import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import "@mdxeditor/editor/style.css";
import Markdown from "react-markdown";
import styles from "../../../../SummaryInfoBlock.module.css";
import { useTranslation } from "react-i18next";

interface ISummaryMessageProps {
  text: string;
  isSummaryDeleted: boolean;
  onRemoveBinding: () => void;
}

const SummaryMessage: React.FC<ISummaryMessageProps> = ({
  text,
  isSummaryDeleted,
  onRemoveBinding,
}) => {
  const { t } = useTranslation();
  const onRemove = () => {
    onRemoveBinding();
  };

  return (
    <div className="flex flex-col">
      <Markdown
        className={
          styles.markdownBlock +
          " p-2 bg-gray-200 dark:bg-[#2B3544] border border-gray-300 dark:border-gray-500 rounded-t rounded-bl ml-3 text-sm max-h-32 overflow-y-auto"
        }
      >
        {text}
      </Markdown>
      <button
        className=" relative p-1 border border-white bg-red-500 hover:bg-red-600 text-white text-xs rounded-b-lg self-end"
        onClick={onRemove}
        disabled={isSummaryDeleted}
      >
        {t("chatAI.unAttach")}
      </button>
    </div>
  );
};

export { SummaryMessage };
