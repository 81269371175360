import React, { useEffect, useRef, useState } from "react";
import { FaArrowRight, FaGem, FaHeart, FaPlus, FaTimes } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { IListItem } from "../../interfaces/IDocDetails";
import { formatDate } from "./helpers/formatDate";
import Markdown from "react-markdown";
import styles from "../../styles/Markdown.module.css";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";

interface ListItemProps {
  data: IListItem;
  onTagsUpdate: (tags: string[]) => void;
  onTagRemoveFromFilterState: (tag: string) => void;
}

const ListItem: React.FC<ListItemProps> = ({
  data,
  onTagsUpdate,
  onTagRemoveFromFilterState,
}) => {
  const { t } = useTranslation();
  const [isTruncated, setIsTruncated] = useState(true);
  const titleRef = useRef<HTMLSpanElement>(null);
  const [tags, setTags] = useState<string[]>(data.tags || []);
  const [isAddingTag, setIsAddingTag] = useState(false);
  const [newTag, setNewTag] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  const cleanSummary = (data: string): string => {
    return data?.replace(/```markdown\s*|```\s*/g, "");
  };

  const handleAddTag = () => {
    if (newTag.trim() === "") {
      setIsAddingTag(false);
    } else {
      const updatedTags = [...tags, newTag.trim()];
      setTags(updatedTags);
      onTagsUpdate(updatedTags);
      setNewTag("");
      setIsAddingTag(false);
    }
  };

  useEffect(() => {
    if (titleRef.current) {
      setIsTruncated(
        titleRef.current.scrollWidth > titleRef.current.clientWidth,
      );
    }
  }, [data.title]);

  const handleRemoveTag = (index: number) => {
    const removedTag = tags[index];
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
    onTagsUpdate(updatedTags);

    onTagRemoveFromFilterState(removedTag);
  };

  return (
    <div className="px-3 py-2 bg-base-color rounded-lg shadow-md mb-2 hover:shadow-2xl transform transition duration-300">
      <div className="text-lg text-del-green dark:text-gray-300 font-bold flex justify-between">
        <span
          ref={titleRef}
          onClick={() =>
            window.open(
              `/details/${data.regulatorCode}/${data.case_no}`,
              "_blank",
            )
          }
          className={`hover:underline cursor-pointer text-xl dark:text-del-green ${isTruncated ? `tooltip-${data.id}` : ""} truncate overflow-hidden text-ellipsis whitespace-nowrap tooltip-target`}
        >
          {data.title}
        </span>
        {isTruncated && (
          <Tooltip
            anchorSelect={`.tooltip-${data.id}`}
            place="top"
            positionStrategy="fixed"
            className="text-xs font-light z-50"
            content={data.title}
            style={{ maxWidth: "100vh", whiteSpace: "normal" }}
          />
        )}

        <a
          href={`/details/${data.regulatorCode}/${data.case_no}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaArrowRight
            className={`text-xl icon-del-green focus:outline-none tooltip-anchor-${data.id}`}
          />
        </a>
        <Tooltip
          anchorSelect={`.tooltip-anchor-${data.id}`}
          place="top-end"
          positionStrategy="fixed"
          className="text-xs font-light z-50"
          content={t("tooltips.openOriginalDocument")}
        />
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.dateOfPublication")} </span>
        {formatDate(data.published)}
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.regulators")} </span>
        {!!data.regulatorName ? data.regulatorName : data.regulatorCode}
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.class")} </span>
        {data.class_name}
      </div>
      <div className="text-sm text-secondary-color">
        <Markdown
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          className={`${styles.markdownBlock} text-secondary-color`}
        >
          {`**${t("docInfo.shortSummary")}** ${data.shortSummary ? cleanSummary(data.shortSummary) : "-"}`}
        </Markdown>
      </div>
      <div className="flex flex-wrap mt-1">
        {data?.keywords?.map((keyword, index) => (
          <span
            key={index}
            className="inline-flex items-center justify-center keyword-chip-color"
          >
            {keyword}
          </span>
        ))}
        {tags?.map((tag, index) => (
          <span
            key={index}
            className="inline-flex items-center justify-center tag-chip-color"
          >
            {tag}
            <button
              onClick={() => handleRemoveTag(index)}
              className="ml-1 mt-0.5"
            >
              <FaTimes className={`tooltip-remove-btn-${data.id} text-white`} />
            </button>
          </span>
        ))}
        <Tooltip
          anchorSelect={`.tooltip-remove-btn-${data.id}`}
          place="bottom"
          className="text-xs font-light"
          content={t("tooltips.remove")}
        />
        {isAddingTag ? (
          <input
            ref={inputRef}
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onBlur={handleAddTag}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleAddTag();
              if (e.key === "Escape") setIsAddingTag(false);
            }}
            className="text-xs bg-gray-100 dark:bg-gray-700 dark:text-white p-1 rounded w-32"
            autoFocus
          />
        ) : (
          <button
            onClick={() => {
              setIsAddingTag(true);
              setTimeout(() => inputRef.current?.focus(), 0);
            }}
            className={`tooltip-btn-${data.id} text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-white`}
          >
            <FaPlus />
          </button>
        )}
        <Tooltip
          anchorSelect={`.tooltip-btn-${data.id}`}
          place="bottom"
          className="text-xs font-light"
          content={t("tooltips.addTag")}
        />
      </div>
      <hr className="my-1 border-gray-300 dark:border-gray-600" />
      {data.highlightedText && (
        <div className="text-sm text-base-color">{data.highlightedText}</div>
      )}
      <div className="flex items-center justify-end space-x-2">
        <button>
          <FaGem
            className={
              data.isImportant
                ? "text-del-light-theme-green text-xl dark:text-del-green"
                : "text-gray-400 text-xl hover-btn-del-green dark:text-gray-500"
            }
          />
        </button>
        <button>
          <FaHeart
            className={
              data.isFavourite
                ? "text-del-light-theme-green text-xl dark:text-del-green"
                : "text-gray-400 text-xl hover-btn-del-green dark:text-gray-500"
            }
          />
        </button>
      </div>
    </div>
  );
};

export { ListItem };
