import React from "react";
import { Tooltip } from "react-tooltip";
import { FaEdit, FaSave } from "react-icons/fa";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import styles from "../../SummaryInfoBlock.module.css";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";
import { PiStarFour } from "react-icons/pi";
import { IDocDetails } from "../../../../interfaces/IDocDetails";
import { useTranslation } from "react-i18next";

interface ISummaryTabProps {
  editableData: any;
  editMode: any;
  setEditableData: (data: IDocDetails) => void;
  setEditMode: (mode: any) => void;
  onParseData: (data: string) => void;
}

const SummaryTab: React.FC<ISummaryTabProps> = ({
  editableData,
  editMode,
  setEditableData,
  setEditMode,
  onParseData,
}) => {
  const { t } = useTranslation();
  const fields = [
    { key: "shortSummary", label: t("docDetails.shortSummary") },
    { key: "summary", label: t("docDetails.summary") },
  ];

  return (
    <>
      {fields.map(({ key, label }, index) => (
        <div
          key={key}
          className={`${index === fields.length - 1 ? "" : "border-b-2 border-gray-500 dark:border-gray-300"} mb-2 pb-2`}
        >
          <h4 className="text-xl title-base-color flex items-center justify-between">
            {label}
            <div className="flex">
              {!editMode[key] && (
                <>
                  <button
                    className={`tooltip-${key}`}
                    onClick={() => onParseData(editableData[key])}
                  >
                    <PiStarFour />
                  </button>
                  <Tooltip
                    anchorSelect={`.tooltip-${key}`}
                    place="top"
                    content={t("docDetails.copyToChatAI")}
                  />
                </>
              )}
              <button
                onClick={() =>
                  setEditMode({ ...editMode, [key]: !editMode[key] })
                }
                className="ml-2 text-gray-500 hover:text-gray-700"
              >
                {editMode[key] ? <FaSave /> : <FaEdit />}
              </button>
            </div>
          </h4>
          {editMode[key] ? (
            <div className="flex-grow w-full items-center bg-gray-100 dark:bg-[#2B3544]">
              <MarkdownEditor
                data={editableData[key]}
                onChange={(data: string) =>
                  setEditableData({
                    ...editableData,
                    [key]: data || "",
                  })
                }
              />
            </div>
          ) : (
            <div className="mt-1 p-2 bg-gray-100 dark:bg-[#2B3544] rounded-lg shadow-sm">
              <Markdown
                rehypePlugins={[rehypeRaw]}
                remarkPlugins={[remarkGfm]}
                className={styles.markdownBlock + " text-secondary-color"}
              >
                {editableData[key]}
              </Markdown>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export { SummaryTab };
