import React, { useEffect, useState } from "react";
import { FilteredItems } from "../FilteredItems/FilteredItems";
import { ChatAI } from "../ChatAI/ChatAI";
import { IListItem } from "../../interfaces/IDocDetails";

interface IChatBlockProps {
  listItem: IListItem[];
  offset: number;
}

const ChatBlock: React.FC<IChatBlockProps> = ({ listItem, offset }) => {
  const [selectedDocuments, setSelectedDocuments] = useState<
    { code: string; case_no: string }[]
  >(
    listItem.map((item) => ({
      code: item.code,
      case_no: item.case_no,
    })),
  );
  const [isAllSelected, setIsAllSelected] = useState(true);

  useEffect(() => {
    const allDocuments = listItem.map((item) => ({
      code: item.code,
      case_no: item.case_no,
    }));
    setSelectedDocuments(allDocuments);
    setIsAllSelected(true);
  }, [listItem]);

  const selectAllItems = () => {
    return listItem.map((item) => ({
      code: item.code,
      case_no: item.case_no,
    }));
  };

  const mappedItems = selectAllItems();

  const handleSelectDocuments = (code: string, case_no: string) => {
    setSelectedDocuments((prevSelected) => {
      const exists = prevSelected.find(
        (doc: { code: string; case_no: string }) => doc.case_no === case_no,
      );
      return exists
        ? prevSelected.filter(
            (doc: { code: string; case_no: string }) => doc.case_no !== case_no,
          )
        : [...prevSelected, { code, case_no: case_no }];
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedDocuments([]);
    } else {
      setSelectedDocuments(mappedItems);
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <>
      <div className="w-1/4 flex flex-col overflow-y-auto">
        <div className="flex items-center justify-between px-2">
          <label className="flex items-center title-base-color space-x-2">
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={handleSelectAll}
              className="ml-1 accent-del-light-theme-green dark:accent-del-green"
            />
            <span className="ml-2">Select All</span>
          </label>
        </div>
        {listItem.length > 0 &&
          listItem.map((item: IListItem) => (
            <div
              key={item.id + " " + offset}
              className="text-gray-800 dark:text-gray-300 p-1"
            >
              <FilteredItems
                key={item.id}
                data={item}
                isSelected={
                  !!selectedDocuments.find(
                    (doc) => doc.case_no === item.case_no,
                  )
                }
                onSelect={handleSelectDocuments}
              />
            </div>
          ))}
      </div>
      <div className="w-3/4 shadow-md rounded-lg flex flex-col overflow-x-hidden overflow-y-auto">
        <ChatAI usedIds={mappedItems} selectedDocuments={selectedDocuments} />
      </div>
    </>
  );
};

export { ChatBlock };
