import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOutputAdjustment } from "../../interfaces/OutputAdjustment";

interface IModelWindowProps {
  onClose: () => void;
  outputAdj: IOutputAdjustment;
  onOutputAdjChange: (outputs: IOutputAdjustment) => void;
  isSubmit: boolean;
}

const ModalOutputAdjustments: React.FC<IModelWindowProps> = ({
  onClose,
  outputAdj,
  onOutputAdjChange,
  isSubmit,
}) => {
  const { t } = useTranslation();
  const [activeTone, setActiveTone] = useState<string | null>(
    outputAdj.tone || "Professional",
  );
  const [activeFormat, setActiveFormat] = useState<string | null>(
    outputAdj.format || "Paragraph",
  );
  const [activeLength, setActiveLength] = useState<string | null>(
    outputAdj.length || "Short",
  );

  useEffect(() => {
    const adjustments = {
      tone: activeTone,
      format: activeFormat,
      length: activeLength,
    };

    onOutputAdjChange(adjustments);
  }, [activeTone, activeFormat, activeLength]);

  const getButtonStyle = (isActive: boolean) =>
    isActive
      ? "border-2 border-del-green dark:border-del-light-theme-green bg-del-green dark:bg-del-light-theme-green text-white px-2 py-1 rounded"
      : "border-2 border-gray-300 text-gray-800 dark:text-gray-300 px-2 py-1 rounded hover:bg-gray-300 dark:hover:bg-gray-700";

  return (
    <div
      className={`${isSubmit ? "right-0 bottom-16" : "-right-14 bottom-11"} " absolute p-2 bg-base-color border border-gray-300 dark:border-gray-600 rounded shadow-lg z-50`}
    >
      <div className="flex justify-between items-center mb-2">
        <h2 className="text-sm font-semibold text-gray-800 dark:text-white">
          {t("chatAI.outputAdjustments")}
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
        >
          ✕
        </button>
      </div>

      <div className="mb-4">
        <h3 className="text-xs font-medium mb-2 text-gray-700 dark:text-gray-300">
          {t("chatAI.tone")}
        </h3>
        <div className="flex flex-wrap gap-2">
          <button
            className={getButtonStyle(activeTone === "Professional")}
            onClick={() => setActiveTone("Professional")}
          >
            {t("chatAI.professional")}
          </button>
          <button
            className={getButtonStyle(activeTone === "Casual")}
            onClick={() => setActiveTone("Casual")}
          >
            {t("chatAI.casual")}
          </button>
          <button
            className={getButtonStyle(activeTone === "Funny")}
            onClick={() => setActiveTone("Funny")}
          >
            {t("chatAI.simple")}
          </button>
        </div>
      </div>
      <hr className="my-1 border-gray-300 dark:border-gray-600" />

      <div className="mb-4">
        <h3 className="text-xs font-medium mb-2 text-gray-700 dark:text-gray-300">
          {t("chatAI.format")}
        </h3>
        <div className="flex flex-wrap gap-2">
          <button
            className={getButtonStyle(activeFormat === "Paragraph")}
            onClick={() => setActiveFormat("Paragraph")}
          >
            {t("chatAI.paragraph")}
          </button>
          <button
            className={getButtonStyle(activeFormat === "E-mail")}
            onClick={() => setActiveFormat("E-mail")}
          >
            {t("chatAI.email")}
          </button>
          <button
            className={getButtonStyle(activeFormat === "Ideas")}
            onClick={() => setActiveFormat("Ideas")}
          >
            {t("chatAI.ideas")}
          </button>
          <button
            className={getButtonStyle(activeFormat === "Article")}
            onClick={() => setActiveFormat("Article")}
          >
            {t("chatAI.article")}
          </button>
        </div>
      </div>
      <hr className="my-1 border-gray-300 dark:border-gray-600" />

      <div>
        <h3 className="text-xs font-medium mb-2 text-gray-700 dark:text-gray-300">
          {t("chatAI.length")}
        </h3>
        <div className="flex flex-wrap gap-2">
          <button
            className={getButtonStyle(activeLength === "Short")}
            onClick={() => setActiveLength("Short")}
          >
            {t("chatAI.short")}
          </button>
          <button
            className={getButtonStyle(activeLength === "Medium")}
            onClick={() => setActiveLength("Medium")}
          >
            {t("chatAI.medium")}
          </button>
          <button
            className={getButtonStyle(activeLength === "Long")}
            onClick={() => setActiveLength("Long")}
          >
            {t("chatAI.long")}
          </button>
        </div>
      </div>
    </div>
  );
};

export { ModalOutputAdjustments };
