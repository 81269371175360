import { axiosApi } from "../../../api/dbAxios";

export const fetchData = async (currentPage: number, filters: any = {}) => {
  try {
    if (filters.date_from && !filters.date_to) {
      filters.date_to = filters.date_from;
    }
    if (filters.date_issue_from && !filters.date_issue_to) {
      filters.date_issue_to = filters.date_issue_from;
    }

    const paramsArray = Object.entries(filters)
      .filter(([_, value]) =>
        Array.isArray(value)
          ? value.length > 0
          : value !== null && value !== "",
      )
      .flatMap(([key, value]) =>
        Array.isArray(value)
          ? value.map((v) => [key, String(v).replace(/'/g, "")])
          : [[key, encodeURIComponent(String(value).replace(/'/g, ""))]],
      );

    const params = new URLSearchParams();
    paramsArray.forEach(([key, value]) => {
      if (value !== null) {
        params.append(key, value);
      }
    });

    const response = await axiosApi.get(
      `/items/?limit=70&offset=${currentPage * 70}`,
      {
        params,
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching item list:", error);
    return [];
  }
};
