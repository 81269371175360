import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt, FaTimes } from "react-icons/fa";
import "./CustomDatePicker.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useTranslation } from "react-i18next";

interface ICustomDatePickerProps {
  startDate: Date;
  endDate?: Date;
  onChange: (dates: any) => void;
  onClear?: () => void;
  minDate?: Date;
  maxDate?: Date;
  placeholderText?: string;
  isRange: boolean;
}

const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
  startDate,
  endDate,
  onChange,
  onClear,
  minDate,
  maxDate,
  placeholderText = "From - To",
  isRange,
}) => {
  const { t } = useTranslation();
  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    onChange([yesterday, yesterday]);
  };

  return (
    <div className="relative">
      {isRange ? (
        <DatePicker
          selected={startDate}
          onChange={(dates) => onChange(dates as [Date | null, Date | null])}
          startDate={startDate}
          endDate={endDate}
          selectsRange={true}
          dateFormat="MM/dd/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          placeholderText={
            placeholderText === "From - To"
              ? t("filtersSection.fromToPlaceholder")
              : placeholderText
          }
          className="border border-gray-500 dark:border-gray-600 p-1.5 pl-4 pr-9 z-50 bg-base-color text-gray-800 dark:text-gray-300 focus:outline-none range-datepicker"
          wrapperClassName="w-full"
          showPopperArrow={true}
          calendarClassName="custom-calendar"
          popperPlacement="top-end"
        />
      ) : (
        <DatePicker
          selected={startDate}
          onChange={(date) => onChange(date as Date)}
          dateFormat="MM/dd/yyyy"
          className="w-full border border-gray-500 dark:border-gray-600 p-1.5 pl-2 pr-9 text-base text-gray-800 dark:text-gray-300 bg-base-color focus:outline-none"
          wrapperClassName="w-full"
          showPopperArrow={true}
          calendarClassName="custom-calendar"
          popperPlacement="bottom-end"
        />
      )}

      {(startDate || endDate) && isRange ? (
        <FaTimes
          className="absolute right-14 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300 cursor-pointer hover:text-red-500"
          onClick={onClear}
        />
      ) : (
        <FaCalendarAlt
          className={`absolute top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300 pointer-events-none ${isRange ? "right-14" : "right-4"}`}
        />
      )}

      {isRange && (
        <div onClick={handleYesterdayClick}>
          <Tooltip
            anchorSelect={`.tooltip-anchor`}
            place="bottom"
            className="text-xs font-light"
            content={t("filtersSection.setYesterdaysDate")}
          />
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 500 512"
            className="tooltip-anchor absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300 cursor-pointer hover:text-blue-500 focus:outline-none"
            height="30px"
            width="30px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#4b830d"
              d="M12 192h476c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H60c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm488-44v-36c0-26.5-21.5-48-48-48h-52V12c0-6.6-5.4-12-12-12h-44c-6.6 0-12 5.4-12 12v52H208V12c0-6.6-5.4-12-12-12h-44c-6.6 0-12 5.4-12 12v52H60c-26.5 0-48 21.5-48 48v36c0 6.6 5.4 12 12 12h476c6.6 0 12-5.4 12-12z"
            ></path>

            <path
              d="M40 356 L360 356"
              fill="none"
              stroke="#808080"
              strokeWidth="90"
            ></path>
            <path
              d="M40 356 L140 256"
              fill="none"
              stroke="#808080"
              strokeWidth="90"
              strokeLinecap="round"
            ></path>
            <path
              d="M40 356 L140 456"
              fill="none"
              stroke="#808080"
              strokeWidth="90"
              strokeLinecap="round"
            ></path>
            <path
              d="M380 356 L410 356"
              fill="none"
              stroke="#808080"
              strokeWidth="90"
            ></path>
            <path
              d="M430 356 L460 356"
              fill="none"
              stroke="#808080"
              strokeWidth="90"
            ></path>

            <path
              d="M40 356 L360 356"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="80"
            ></path>
            <path
              d="M40 356 L140 256"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="80"
              strokeLinecap="round"
            ></path>
            <path
              d="M40 356 L140 456"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="80"
              strokeLinecap="round"
            ></path>
            <path
              d="M380 356 L410 356"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="80"
            ></path>
            <path
              d="M430 356 L460 356"
              fill="none"
              stroke="#FFFFFF"
              strokeWidth="80"
            ></path>
          </svg>
        </div>
      )}
    </div>
  );
};

export { CustomDatePicker };
