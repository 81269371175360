import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  FaUserCircle,
  FaSun,
  FaMoon,
  FaSignOutAlt,
  FaListAlt,
  FaHeart,
  FaGem,
} from "react-icons/fa";
import { IoDiamondSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import { useLocation, matchPath } from "react-router-dom";
import { IDocDetails } from "../../pages/DetailsPage/interfaces/IDocDetails";
import { MockDocuments } from "../../pages/HomePage/types/MockDocuments";
import { useTranslation } from "react-i18next";

interface IHeaderProps {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  onDocTypeChange: (onDocTypeValue: string) => void;
}

const Header: React.FC<IHeaderProps> = ({
  onDocTypeChange,
  darkMode,
  setDarkMode,
}) => {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState<"all" | "important" | "favourite">(
    "all",
  );
  const [isChatButtonActive, setIsChatButtonActive] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === "/recent";
  const isChatAi = location.pathname === "/chatAI";
  const match = matchPath("/details/:id", location.pathname);
  const id = match ? match.params.id : "";
  let document = MockDocuments.find((doc: IDocDetails) => doc.id === id);
  const navigate = useNavigate();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleTabClick = (tab: "all" | "important" | "favourite") => {
    setActiveTab(tab);
    onDocTypeChange(tab);
  };

  const handleLogoClick = () => {
    navigate("/recent");
  };

  const toggleChatButton = () => {
    const newStatus = !isChatButtonActive;
    setIsChatButtonActive(newStatus);
    localStorage.setItem("chatActive", newStatus.toString());
    if (newStatus) {
      navigate("/chatAI");
    } else {
      navigate("/recent");
    }
  };

  const handleLanguageChange = (selectedOption: {
    value: string;
    label: string;
  }) => {
    i18n.changeLanguage(selectedOption.value);
    localStorage.setItem("language", selectedOption.value);
  };

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "pl", label: "Polish" },
    { value: "cs", label: "Czech" },
    { value: "ro", label: "Romanian" },
    { value: "hu", label: "Hungarian" },
  ];

  return (
    <header className="bg-gray-100 dark:bg-gray-800 py-1 px-4 flex justify-between items-center shadow-xl">
      <div className="flex items-center space-x-2 w-1/4">
        <img
          src={logo}
          alt="Logo"
          className="h-10 cursor-pointer"
          onClick={handleLogoClick}
        />
        <h1
          className="text-2xl font-bold title-base-color cursor-pointer relative after:content-[''] after:absolute after:left-0 after:bottom-0 after:w-full after:h-[2px] after:bg-black dark:after:bg-white after:scale-x-0 hover:after:scale-x-100 after:origin-left after:transition-transform after:duration-300"
          onClick={handleLogoClick}
        >
          Codet
        </h1>
      </div>
      <div
        className={`flex ${isHomePage ? "justify-between" : "justify-end"} w-3/4 pl-5`}
      >
        {isHomePage && (
          <>
            <div className="flex space-x-6">
              <button
                onClick={() => handleTabClick("all")}
                className={`flex items-center space-x-2 ${
                  activeTab === "all"
                    ? "icon-del-green"
                    : "text-gray-600 dark:text-gray-300"
                } hover-btn-del-green`}
              >
                <FaListAlt /> <span>{t("tabs.all")}</span>
              </button>

              <button
                onClick={() => handleTabClick("important")}
                className={`flex items-center space-x-2 ${
                  activeTab === "important"
                    ? "icon-del-green"
                    : "text-gray-600 dark:text-gray-300"
                } hover-btn-del-green`}
              >
                <IoDiamondSharp /> <span>{t("tabs.important")}</span>
              </button>

              <button
                onClick={() => handleTabClick("favourite")}
                className={`flex items-center space-x-2 ${
                  activeTab === "favourite"
                    ? "icon-del-green"
                    : "text-gray-600 dark:text-gray-300"
                } hover-btn-del-green`}
              >
                <FaHeart /> <span>{t("tabs.favourite")}</span>
              </button>
            </div>
          </>
        )}
        <div className="flex items-center space-x-3">
          {!isChatAi && !isHomePage && (
            <>
              <div className="flex items-center justify-end space-x-4">
                <button>
                  <FaGem
                    className={
                      document?.isImportant
                        ? "text-del-green text-xl"
                        : "text-gray-400 text-xl hover-btn-del-green dark:text-gray-500"
                    }
                  />
                </button>
                <button>
                  <FaHeart
                    className={
                      document?.isFavourite
                        ? "text-del-green text-xl"
                        : "text-gray-400 text-xl hover-btn-del-green dark:text-gray-500"
                    }
                  />
                </button>
              </div>
              <hr className="h-6 border-l border-gray-300 dark:border-gray-600 mx-2" />
            </>
          )}

          {(isHomePage || isChatAi) && (
            <>
              <button
                onClick={toggleChatButton}
                className={`relative text-gray-700 dark:text-gray-300 w-11 text-left hover-btn-del-green border-b-2 border-del-green`}
              >
                {isHomePage ? (
                  <>
                    {t("chatButton")}
                    <div className="absolute -top-2 -right-1 text-del-green font-bold text-sm">
                      {t("aiLabel")}
                    </div>
                  </>
                ) : (
                  t("searchButton")
                )}
              </button>

              <hr className="h-6 border-l border-gray-300 dark:border-gray-600 mx-4" />
            </>
          )}
          <div className="relative inline-block w-auto max-w-[125px]">
            <Select
              isSearchable={false}
              options={languageOptions}
              value={languageOptions.find(
                (option) => option.value === i18n.language,
              )}
              onChange={(selectedOption) =>
                handleLanguageChange(selectedOption!)
              }
              classNamePrefix="react-select"
              styles={{
                control: (base) => ({
                  ...base,
                  backgroundColor: darkMode ? "#4B5563" : "#E5E7EB",
                  color: darkMode ? "#D1D5DB" : "#374151",
                  borderColor: darkMode ? "#374151" : "#9CA3AF",
                  maxWidth: "125px",
                  minWidth: "80px",
                  borderRadius: "15px",
                  outlineColor: "#86BC25",
                }),
                container: (base) => ({
                  ...base,
                  maxWidth: "125px",
                  minWidth: "80px",
                }),
                singleValue: (base) => ({
                  ...base,
                  color: darkMode ? "#D1D5DB" : "#374151",
                }),
                menu: (base) => ({
                  ...base,
                  backgroundColor: darkMode ? "#374151" : "#FFFFFF",
                  marginTop: 0,
                }),
                option: (base, { isFocused }) => ({
                  ...base,
                  backgroundColor: isFocused
                    ? darkMode
                      ? "#86BC25"
                      : "#86BC25"
                    : darkMode
                      ? "#374151"
                      : "#FFFFFF",
                  color: darkMode ? "#FFFFFF" : "#000000",
                }),
                dropdownIndicator: (base) => ({
                  ...base,
                  paddingRight: "2px",
                  paddingLeft: "2px",
                }),
              }}
            />
          </div>
          <button
            onClick={toggleDarkMode}
            className="p-1.5 rounded-full bg-gray-200 dark:bg-gray-600"
          >
            {darkMode ? (
              <FaSun className="text-yellow-500 h-5 w-5" />
            ) : (
              <FaMoon className="text-gray-700 dark:text-yellow-500 h-5 w-5" />
            )}
          </button>

          <div className="relative">
            <button className="flex items-center p-1.5 bg-gray-200 dark:bg-gray-600 rounded-full">
              <FaUserCircle className="h-5 w-5 text-gray-700 dark:text-gray-300" />
            </button>
          </div>

          <hr className="h-6 border-l border-gray-300 dark:border-gray-600 mx-2" />

          <button className="flex items-center space-x-1 px-2 py-1 bg-del-light-theme-green hover:bg-del-green text-white rounded-md dark:bg-del-dark-theme-dark-green dark:hover:bg-del-green">
            <FaSignOutAlt /> <span>{t("logOut")}</span>
          </button>
        </div>
      </div>
    </header>
  );
};

export { Header };
