import React from "react";
import "@mdxeditor/editor/style.css";
import styles from "./MarkdownEditor.module.css";
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from "@mdxeditor/editor";

interface IMarkdownEditorProps {
  data: string;
  onChange: (newValue: string) => void;
}

const MarkdownEditor: React.FunctionComponent<IMarkdownEditorProps> = ({
  data,
  onChange,
}) => {
  return (
    <MDXEditor
      markdown={data}
      onChange={onChange}
      contentEditableClassName={styles.editor + " text-secondary-color"}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        thematicBreakPlugin(),
        quotePlugin(),
        tablePlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <div className="flex flex-wrap w-full text-xs">
              <UndoRedo /> <Separator />
              <BoldItalicUnderlineToggles />
              <BlockTypeSelect /> <Separator /> <ListsToggle />
              <Separator />
              <InsertTable /> <CreateLink />
            </div>
          ),
        }),
      ]}
    />
  );
};

export { MarkdownEditor };
