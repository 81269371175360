import React from "react";
import { Chip } from "./components/Chip/Chip";
import { useTranslation } from "react-i18next";

interface FiltersProps {
  filters: {
    keywords: string[];
    tags: string[];
    country: string[];
    regulator: string[];
    class_name: string[];
    date_from: Date | null;
    date_to: Date | null;
    date_issue_from: Date | null;
    date_issue_to: Date | null;
  };
  onFiltersChange: (updatedFilters: FiltersProps["filters"]) => void;
}

const FilterChips: React.FC<FiltersProps> = ({ filters, onFiltersChange }) => {
  const { t } = useTranslation();
  const removeFilter = (
    filterName: keyof FiltersProps["filters"],
    value?: string,
  ) => {
    const updatedFilters = { ...filters };
    if (filterName === "tags") {
      updatedFilters.tags = updatedFilters.tags.filter((tag) => tag !== value);
    } else if (filterName === "keywords") {
      updatedFilters.keywords = updatedFilters.keywords.filter(
        (keyword) => keyword !== value,
      );
    } else if (filterName === "country") {
      updatedFilters.country = updatedFilters.country.filter(
        (country) => country !== value,
      );
    } else if (filterName === "regulator") {
      updatedFilters.regulator = updatedFilters.regulator.filter(
        (regulator) => regulator !== value,
      );
      if (updatedFilters.regulator.length === 0) updatedFilters.class_name = [];
    } else if (filterName === "class_name") {
      updatedFilters.class_name = updatedFilters.class_name.filter(
        (classItem) => classItem !== value,
      );
    } else if (filterName === "date_from" && updatedFilters.date_to !== null) {
      updatedFilters.date_from = updatedFilters.date_to;
      updatedFilters.date_to = null;
    } else if (
      filterName === "date_issue_from" &&
      updatedFilters.date_issue_to !== null
    ) {
      updatedFilters.date_issue_from = updatedFilters.date_issue_to;
      updatedFilters.date_issue_to = null;
    } else {
      updatedFilters[filterName] = null;
    }

    onFiltersChange(updatedFilters);
  };

  const renderChips = () => {
    const chips: JSX.Element[] = [];

    (Object.keys(filters) as (keyof typeof filters)[]).forEach((filterName) => {
      const filterValue = filters[filterName];

      if (Array.isArray(filterValue) && filterValue.length) {
        filterValue.forEach((item) => {
          chips.push(
            <Chip
              key={item}
              label={item}
              onDelete={() => removeFilter(filterName, item)}
            />,
          );
        });
      } else if (
        (filterName === "date_from" || filterName === "date_to") &&
        filterValue instanceof Date
      ) {
        if (
          filters.date_from &&
          filters.date_to &&
          filters.date_from.getTime() === filters.date_to.getTime()
        ) {
          onFiltersChange({
            ...filters,
            date_from: filters.date_to,
            date_to: null,
          });
        }
        chips.push(
          <Chip
            key={filterName}
            label={`${t("filtersSection.dateOfPublication")} ${filterName === "date_from" ? t("filtersSection.from") : t("filtersSection.until")} ${new Date(filterValue).toLocaleDateString()}`}
            onDelete={() => removeFilter(filterName, filterValue?.toString())}
          />,
        );
      } else if (
        (filterName === "date_issue_from" || filterName === "date_issue_to") &&
        filterValue instanceof Date
      ) {
        if (
          filters.date_issue_from &&
          filters.date_issue_to &&
          filters.date_issue_from.getTime() === filters.date_issue_to.getTime()
        ) {
          onFiltersChange({
            ...filters,
            date_issue_from: filters.date_issue_to,
            date_issue_to: null,
          });
        }
        chips.push(
          <Chip
            key={filterName}
            label={`${t("filtersSection.dateOfIssue")} ${filterName === "date_issue_from" ? t("filtersSection.from") : t("filtersSection.until")}} ${new Date(filterValue).toLocaleDateString()}`}
            onDelete={() => removeFilter(filterName, filterValue?.toString())}
          />,
        );
      }
    });

    return chips;
  };

  return <div className="flex flex-wrap gap-x-2">{renderChips()}</div>;
};

export { FilterChips };
