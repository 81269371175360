import React, { useState, useEffect, useRef } from "react";
import { FaArrowUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { getChatResponseData } from "./services/chatResponse.service";
import { useMutation } from "@tanstack/react-query";
import { IMessage, IUsedIds } from "./interfaces/message";
import { PiBroom } from "react-icons/pi";

interface IChatAIProps {
  usedIds: IUsedIds[];
  selectedDocuments: { code: string; case_no: string }[];
}

const ChatAI: React.FC<IChatAIProps> = ({ usedIds, selectedDocuments }) => {
  const { t } = useTranslation();
  const [valueConversationInput, setValueConversationInput] =
    useState<string>("");
  const [waitingMessage, setWaitingMessage] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [isTypingResponse, setIsTypingResponse] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const responseSources = "Sources: url";

  const {
    mutate: fetchChatResponse,
    isPending,
    data,
  } = useMutation({
    mutationFn: () =>
      getChatResponseData(
        selectedDocuments.length > 0 ? selectedDocuments : usedIds,
        messages,
      ),
  });

  const handleSubmit = (val: string) => {
    if (val.trim()) {
      fetchChatResponse();
      setValueConversationInput("");
      setWaitingMessage("");
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", text: val },
      ]);
    }
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    val: string,
  ) => {
    if (isPending || isTypingResponse) {
      return;
    }
    if (event.key === "Enter" && val.trim()) {
      handleSubmit(val);
    }
  };

  useEffect(() => {
    if (isPending) {
      let count = 0;
      const interval = setInterval(() => {
        setWaitingMessage(".".repeat((count % 3) + 1));
        count += 1;
      }, 500);

      return () => {
        clearInterval(interval);
      };
    } else if (!isPending) {
      setWaitingMessage("");
      startTypingResponse();
    }
  }, [isPending]);

  const startTypingResponse = () => {
    if (data && data.length > 1) {
      setIsTypingResponse(true);
      let index = 0;

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", text: "" },
      ]);

      const typingInterval = setInterval(() => {
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];

          if (lastMessage.role === "assistant") {
            const updatedText = data.slice(0, index + 1);
            index += 1;

            if (index === data.length) {
              clearInterval(typingInterval);
              setIsTypingResponse(false);

              return [
                ...prevMessages.slice(0, -1),
                { ...lastMessage, text: updatedText, source: responseSources },
              ];
            }

            return [
              ...prevMessages.slice(0, -1),
              { ...lastMessage, text: updatedText },
            ];
          }
          return prevMessages;
        });
      }, 10);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, waitingMessage]);

  return (
    <div className="bg-gray-100 border border-gray-300 dark:border-gray-600 dark:bg-gray-700 shadow-md text-secondary-color h-full flex relative overflow-hidden">
      <div className="w-full relative transition-all duration-300 flex flex-col border-gray-300 pl-4 pb-2">
        <div
          className="flex-grow overflow-y-auto mb-4 space-y-4 pt-4 pr-4"
          ref={chatContainerRef}
        >
          {messages.map((message, index) => (
            <div
              key={index}
              className={`max-w-max p-3 rounded-lg ${
                message.role === "user"
                  ? "bg-gray-300 dark:bg-gray-500 text-gray-800 dark:text-white self-end text-right ml-auto"
                  : "bg-gray-400 dark:bg-gray-800 text-gray-800 dark:text-white self-start text-left"
              }`}
            >
              {message.text}
              {message.source && (
                <div className="mt-2 text-sm text-secondary-color">
                  {message.source}
                </div>
              )}
            </div>
          ))}
          {isPending && waitingMessage && (
            <span className="p-2 bg-gray-400 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg self-start inline-block">
              {waitingMessage}
            </span>
          )}
        </div>
        <div className="flex items-center pr-4">
          <div className="w-full">
            <input
              type="text"
              placeholder={t("chatAI.askLegalQuestion")}
              className="w-full input-chatai-color"
              value={valueConversationInput}
              onChange={(e) => setValueConversationInput(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, valueConversationInput)}
            />
            <button
              className="absolute right-14 bottom-2.5 transform -translate-y-1/2 bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-light-theme-green text-white"
              onClick={() => {
                if (!isPending && !isTypingResponse)
                  handleSubmit(valueConversationInput);
              }}
            >
              <FaArrowUp />
            </button>
          </div>
          <div className="mx-2.5">
            <button
              className="absolute right-1 bottom-5 text-xl bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-light-theme-green text-white"
              onClick={() => setMessages([])}
            >
              <PiBroom />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ChatAI };
