import { IDocDetails } from "../../DetailsPage/interfaces/IDocDetails";

export const MockDocuments: any[] = [
  {
    title: "10 Afs 140/2021 - 53",
    caseId: "364878-cHash__e3c359fec68cf769c267272de192b0be",
    className: "Value added tax",
    party: "JMB-STEEL s.r.o., Odvolací finanční ředitelství",
    provisions:
      "The text of the decision does not specifically mention any sections, paragraphs, or articles of provision 353/2003 Sb. Therefore, there are no relevant sections to extract and return. The text of the court decision did not mention any specific provisions from the law No. 338/1992 Sb. Therefore, there are no relevant sections, paragraphs, or articles to extract.§ 36 zákona č. 586/1992 Sb.§ 237 zákona č. 280/2009 Sb.",
    participants: "Netherlands",
    keywords: [
      "VAT fraud",
      "Tax deductibility of costs",
      "Economic rationality",
      "Good faith",
      "Advertising costs",
    ],
    summary:
      "Předmětem sporu byla právní otázka týkající se souladu čl. IV bodu 4 zákona č. 364/2019 Sb., který se zaměřuje na zdanění úrokových příjmů z korunových dluhopisů, jež byly emitovány před 1. lednem 2013, s ústavním pořádkem České republiky.  Soud jednoznačně potvrdil nároky žalovaného tím, že schválil legalitu změny zdanění úrokových příjmů z dluhopisů. Tato úprava byla považována za souladnou se zákonem a bez rozporu s ústavním pořádkem. Soud konstatoval, že nová daňová úprava neporušuje ústavní principy, jelikož nedochází k extrémní disproporcionalitě ani narušení zásady rovnosti. Tímto rozhodnutím byla potvrzena oprávněnost změny a byla zamítnuta obvinění z její protiústavnosti.  Žaloba se zamítá.', 'Short summary': 'Spor se týkal souladu právní úpravy zdanění úrokových příjmů z korunových dluhopisů, emitovaných před 1. lednem 2013, s ústavním pořádkem ČR dle čl. IV bodu 4 zákona č. 364/2019 Sb. Soud potvrdil oprávněnost změny zdanění a shledal ji jako legální a v souladu s ústavním pořádkem, neboť nepředstavuje extrémní disproporcionalitu ani porušení zásady rovnosti. Žaloba zpochybňující ústavnost této úpravy byla proto zamítnuta.",
    shortSummary:
      "The Supreme Administrative Court of the Czech Republic rejected JMB-STEEL s.r.o.'s cassation complaint regarding VAT fraud. The court found that the plaintiff should have known about the fraud and did not take sufficient preventive measures.",
    decision:
      "The court rejected the plaintiff's cassation complaint and found that JMB-STEEL s.r.o. did not take sufficient measures to prevent participation in VAT fraud by failing to compare offers or perform marketing analysis. No compensation for costs was awarded and appeals are not admissible.",
    regulator: "Supreme Administrative Court of the Czech Republic",
    regulatorCode: "sac",
    published: "28.03.2023",
    link: "https://www.nssoud.cz/modalni-obsah/rozhodovaci-cinnost/rozhodnuti-364861?cHash=0544fd02b44ddb3256826aade0ab23fc",
    id: "725084",
    isFavourite: true,
    isImportant: false,
  },
  {
    title: "10 Afs 140/2021 - 54",
    caseId: "364878-cHash__e3c359fec68cf769c267272de192b0be",
    className: "Value added tax",
    party: "JMB-STEEL s.r.o., Odvolací finanční ředitelství",
    provisions: "No provisions from the list",
    participants: "Netherlands",
    keywords: [
      "VAT fraud",
      "Tax deductibility of costs",
      "Economic rationality",
      "Good faith",
      "Advertising costs",
    ],
    summary:
      "Předmětem sporu byla právní otázka týkající se souladu čl. IV bodu 4 zákona č. 364/2019 Sb., který se zaměřuje na zdanění úrokových příjmů z korunových dluhopisů, jež byly emitovány před 1. lednem 2013, s ústavním pořádkem České republiky.  Soud jednoznačně potvrdil nároky žalovaného tím, že schválil legalitu změny zdanění úrokových příjmů z dluhopisů. Tato úprava byla považována za souladnou se zákonem a bez rozporu s ústavním pořádkem. Soud konstatoval, že nová daňová úprava neporušuje ústavní principy, jelikož nedochází k extrémní disproporcionalitě ani narušení zásady rovnosti. Tímto rozhodnutím byla potvrzena oprávněnost změny a byla zamítnuta obvinění z její protiústavnosti.  Žaloba se zamítá.', 'Short summary': 'Spor se týkal souladu právní úpravy zdanění úrokových příjmů z korunových dluhopisů, emitovaných před 1. lednem 2013, s ústavním pořádkem ČR dle čl. IV bodu 4 zákona č. 364/2019 Sb. Soud potvrdil oprávněnost změny zdanění a shledal ji jako legální a v souladu s ústavním pořádkem, neboť nepředstavuje extrémní disproporcionalitu ani porušení zásady rovnosti. Žaloba zpochybňující ústavnost této úpravy byla proto zamítnuta.",
    shortSummary:
      "The Supreme Administrative Court of the Czech Republic rejected JMB-STEEL s.r.o.'s cassation complaint regarding VAT fraud. The court found that the plaintiff should have known about the fraud and did not take sufficient preventive measures.",
    decision:
      "The court rejected the plaintiff's cassation complaint and found that JMB-STEEL s.r.o. did not take sufficient measures to prevent participation in VAT fraud by failing to compare offers or perform marketing analysis. No compensation for costs was awarded and appeals are not admissible.",
    regulator: "Supreme Administrative Court of the Czech Republic",
    regulatorCode: "sac",
    published: "28.03.2023",
    link: "https://www.nssoud.cz/modalni-obsah/rozhodovaci-cinnost/rozhodnuti-364861?cHash=0544fd02b44ddb3256826aade0ab23fc",
    id: "725323",
    isFavourite: false,
    isImportant: true,
  },
  {
    title: "10 Afs 140/2021 - 55",
    caseId: "364878-cHash__e3c359fec68cf769c267272de192b0be",
    className: "Value added tax",
    party: "JMB-STEEL s.r.o., Odvolací finanční ředitelství",
    provisions: "No provisions from the list",
    participants: "Netherlands",
    keywords: [
      "VAT fraud",
      "Tax deductibility of costs",
      "Economic rationality",
      "Good faith",
      "Advertising costs",
    ],
    summary:
      "Předmětem sporu byla právní otázka týkající se souladu čl. IV bodu 4 zákona č. 364/2019 Sb., který se zaměřuje na zdanění úrokových příjmů z korunových dluhopisů, jež byly emitovány před 1. lednem 2013, s ústavním pořádkem České republiky.  Soud jednoznačně potvrdil nároky žalovaného tím, že schválil legalitu změny zdanění úrokových příjmů z dluhopisů. Tato úprava byla považována za souladnou se zákonem a bez rozporu s ústavním pořádkem. Soud konstatoval, že nová daňová úprava neporušuje ústavní principy, jelikož nedochází k extrémní disproporcionalitě ani narušení zásady rovnosti. Tímto rozhodnutím byla potvrzena oprávněnost změny a byla zamítnuta obvinění z její protiústavnosti.  Žaloba se zamítá.', 'Short summary': 'Spor se týkal souladu právní úpravy zdanění úrokových příjmů z korunových dluhopisů, emitovaných před 1. lednem 2013, s ústavním pořádkem ČR dle čl. IV bodu 4 zákona č. 364/2019 Sb. Soud potvrdil oprávněnost změny zdanění a shledal ji jako legální a v souladu s ústavním pořádkem, neboť nepředstavuje extrémní disproporcionalitu ani porušení zásady rovnosti. Žaloba zpochybňující ústavnost této úpravy byla proto zamítnuta.",
    shortSummary:
      "The Supreme Administrative Court of the Czech Republic rejected JMB-STEEL s.r.o.'s cassation complaint regarding VAT fraud. The court found that the plaintiff should have known about the fraud and did not take sufficient preventive measures.",
    decision:
      "The court rejected the plaintiff's cassation complaint and found that JMB-STEEL s.r.o. did not take sufficient measures to prevent participation in VAT fraud by failing to compare offers or perform marketing analysis. No compensation for costs was awarded and appeals are not admissible.",
    regulator: "Supreme Administrative Court of the Czech Republic",
    regulatorCode: "sac",
    published: "28.03.2023",
    link: "https://www.nssoud.cz/modalni-obsah/rozhodovaci-cinnost/rozhodnuti-364861?cHash=0544fd02b44ddb3256826aade0ab23fc",
    id: "725376",
    isFavourite: false,
    isImportant: false,
  },
  {
    title: "10 Afs 140/2021 - 56",
    caseId: "364878-cHash__e3c359fec68cf769c267272de192b0be",
    className: "Value added tax",
    party: "JMB-STEEL s.r.o., Odvolací finanční ředitelství",
    provisions: "No provisions from the list",
    participants: "Netherlands",
    keywords: [
      "VAT fraud",
      "Tax deductibility of costs",
      "Economic rationality",
      "Good faith",
      "Advertising costs",
    ],
    summary:
      "Předmětem sporu byla právní otázka týkající se souladu čl. IV bodu 4 zákona č. 364/2019 Sb., který se zaměřuje na zdanění úrokových příjmů z korunových dluhopisů, jež byly emitovány před 1. lednem 2013, s ústavním pořádkem České republiky.  Soud jednoznačně potvrdil nároky žalovaného tím, že schválil legalitu změny zdanění úrokových příjmů z dluhopisů. Tato úprava byla považována za souladnou se zákonem a bez rozporu s ústavním pořádkem. Soud konstatoval, že nová daňová úprava neporušuje ústavní principy, jelikož nedochází k extrémní disproporcionalitě ani narušení zásady rovnosti. Tímto rozhodnutím byla potvrzena oprávněnost změny a byla zamítnuta obvinění z její protiústavnosti.  Žaloba se zamítá.', 'Short summary': 'Spor se týkal souladu právní úpravy zdanění úrokových příjmů z korunových dluhopisů, emitovaných před 1. lednem 2013, s ústavním pořádkem ČR dle čl. IV bodu 4 zákona č. 364/2019 Sb. Soud potvrdil oprávněnost změny zdanění a shledal ji jako legální a v souladu s ústavním pořádkem, neboť nepředstavuje extrémní disproporcionalitu ani porušení zásady rovnosti. Žaloba zpochybňující ústavnost této úpravy byla proto zamítnuta.",
    shortSummary:
      "The Supreme Administrative Court of the Czech Republic rejected JMB-STEEL s.r.o.'s cassation complaint regarding VAT fraud. The court found that the plaintiff should have known about the fraud and did not take sufficient preventive measures.",
    decision:
      "The court rejected the plaintiff's cassation complaint and found that JMB-STEEL s.r.o. did not take sufficient measures to prevent participation in VAT fraud by failing to compare offers or perform marketing analysis. No compensation for costs was awarded and appeals are not admissible.",
    regulator: "Supreme Administrative Court of the Czech Republic",
    regulatorCode: "sac",
    published: "28.03.2023",
    link: "https://www.nssoud.cz/modalni-obsah/rozhodovaci-cinnost/rozhodnuti-364861?cHash=0544fd02b44ddb3256826aade0ab23fc",
    id: "725470",
    isFavourite: false,
    isImportant: false,
  },
];
