import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";

interface KeywordsInputProps {
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
}

const KeywordsInput: React.FC<KeywordsInputProps> = ({
  keywords,
  setKeywords,
}) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("darkMode") === "true",
  );

  useEffect(() => {
    setTheme(localStorage.getItem("darkMode") === "true");
  }, [localStorage.getItem("darkMode")]);

  const handleChange = (newValue: any) => {
    setKeywords(newValue.map((item: any) => item.value));
  };

  return (
    <CreatableSelect
      isMulti
      value={keywords.map((keyword) => ({ value: keyword, label: keyword }))}
      onChange={handleChange}
      placeholder="Add keywords..."
      className="border border-gray-500 dark:border-gray-600"
      styles={{
        control: (base: any) => ({
          ...base,
          backgroundColor: theme ? "bg-gray-800" : "#fff",
          borderColor: theme ? "#4a5568" : "#e2e8f0",
          color: theme ? "#e2e8f0" : "#2d3748",
          borderRadius: "none",
        }),
        input: (base: any) => ({
          ...base,
          color: theme ? "#e2e8f0" : "#2d3748",
        }),
        menu: () => ({
          display: "none",
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        dropdownIndicator: () => ({
          display: "none",
        }),
        multiValue: (base: any) => ({
          ...base,
          backgroundColor: theme ? "#43B02A" : "#26890D",
        }),
        multiValueLabel: (base: any) => ({
          ...base,
          color: "#e2e8f0",
        }),
      }}
    />
  );
};

export { KeywordsInput };
