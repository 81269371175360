import React, { useEffect, useState } from "react";
import darkLogo from "../../assets/images/dark_logo_deloitte.png";
import lightLogo from "../../assets/images/light_logo_deloitte.png";
import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import "./HomePage.css";
import { FilterState } from "./interfaces/FilterState";
import { fetchData } from "./services/itemList.service";
import { SearchInput } from "./components/SearchInput/SearchInput";
import { FilterChips } from "./components/Filters/components/FilterChips/FilterChips";
import { formatDate } from "./helpers/formatDate";
import { ListBlock } from "./components/ListBlock/ListBlock";
import { ChatBlock } from "./components/ChatBlock/ChatBlock";
import { IListItem } from "./interfaces/IDocDetails";

const HomePage = () => {
  const location = useLocation();
  const [offsetCount, setOffsetCount] = useState(0);
  const [filterState, setFilterState] = useState<FilterState>({
    country: [],
    regulator: [],
    class_name: [],
    date_from: null,
    date_to: null,
    date_issue_from: null,
    date_issue_to: null,
    keywords: [],
    tags: [],
  });
  const [responseData, setResponseData] = useState<{
    items: IListItem[];
    filters: FilterState;
  }>({
    items: [],
    filters: filterState,
  });

  const { data: data, isLoading } = useQuery({
    queryKey: ["data", offsetCount, filterState],
    queryFn: () =>
      fetchData(offsetCount, {
        ...filterState,
        date_from: formatDate(filterState.date_from),
        date_to: formatDate(filterState.date_to),
        date_issue_from: formatDate(filterState.date_issue_from),
        date_issue_to: formatDate(filterState.date_issue_to),
      }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (data && data.items) {
      if (offsetCount === 0) {
        setResponseData({ items: data.items, filters: data.filters });
      } else {
        setResponseData(
          (prevData: { items: IListItem[]; filters: FilterState }) => ({
            items: [...prevData.items, ...data.items],
            filters: data.filters,
          }),
        );
      }
    }
  }, [data, offsetCount]);

  const handleValueSelected = (selectedValue: string) => {
    setFilterState((prev) => ({
      ...(prev || {}),
      search: selectedValue,
    }));
  };

  const handleFiltersChange = (updatedFilters: FilterState) => {
    setFilterState(updatedFilters);
    setOffsetCount(0);
    setResponseData({
      items: [],
      filters: {
        country: [],
        regulator: [],
        class_name: [],
        date_from: null,
        date_to: null,
        date_issue_from: null,
        date_issue_to: null,
        keywords: [],
        tags: [],
      },
    });
  };

  const handleTagRemoveFromFilterState = (tag: string) => {
    setFilterState((prevState) => ({
      ...prevState,
      tags: prevState.tags.filter((existingTag) => existingTag !== tag),
    }));
  };

  return (
    <div className="h-[calc(100vh-80px)] flex flex-col overflow-hidden">
      {isLoading && <div className="line-loader"></div>}

      <div className="flex flex-col flex-1 overflow-hidden">
        <div>
          <div className="flex items-center mb-3">
            <img
              alt="Logo"
              src={darkLogo}
              className="mr-2 w-32 dark:hidden"
              draggable="false"
            />
            <img
              alt="Logo"
              src={lightLogo}
              className="mr-2 w-32 hidden dark:block"
              draggable="false"
            />
            <SearchInput onInputChange={handleValueSelected} />
          </div>
          <div className="flex justify-between">
            <FilterChips
              filters={filterState}
              onFiltersChange={handleFiltersChange}
            />
          </div>
        </div>

        <div
          className={`${location.pathname === "/recent" ? "" : "hidden"} flex flex-row space-x-4 mt-2 flex-1 min-h-0`}
        >
          <ListBlock
            listData={responseData}
            filtersSelected={filterState}
            data={data}
            isLoading={isLoading}
            offsetCount={offsetCount}
            onFilterChange={(updatedFilters: FilterState) =>
              handleFiltersChange(updatedFilters)
            }
            onOffsetChange={(offsetCount: number) =>
              setOffsetCount(offsetCount)
            }
            onTagRemove={(tag: string) => handleTagRemoveFromFilterState(tag)}
          />
        </div>

        <div
          className={`${location.pathname === "/chatAI" ? "" : "hidden"} flex flex-row space-x-4 mt-2 flex-1 min-h-0`}
        >
          <ChatBlock listItem={responseData.items} offset={offsetCount} />
        </div>
      </div>
    </div>
  );
};

export { HomePage };
