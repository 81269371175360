import React from "react";
import { Tooltip } from "react-tooltip";
import { FaEdit, FaSave } from "react-icons/fa";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import styles from "../../SummaryInfoBlock.module.css";
import { CustomDatePicker } from "../../../../../HomePage/components/Filters/components/CustomDatePicker/CustomDatePicker";
import { formatDate } from "../../../../../HomePage/components/ListItem/helpers/formatDate";
import { AutoResizeTextarea } from "../AutoResizeTextarea/AutoResizeTextarea";
import { MarkdownEditor } from "../MarkdownEditor/MarkdownEditor";
import { KeywordsInput } from "./components/KeywordInput";
import { IDocDetails } from "../../../../interfaces/IDocDetails";
import { useTranslation } from "react-i18next";

interface IInfoTabProps {
  editableData: any;
  editMode: any;
  setEditableData: (data: IDocDetails) => void;
  setEditMode: (mode: any) => void;
  onDateChange: (date: Date) => void;
}

const InfoTab: React.FC<IInfoTabProps> = ({
  editableData,
  editMode,
  setEditableData,
  setEditMode,
  onDateChange,
}) => {
  const { t } = useTranslation();
  const fields = [
    {
      key: "title",
      label: t("docDetails.caseName"),
      type: "text",
    },
    {
      key: "published",
      label: t("docDetails.dateOfPublication"),
      type: "date",
    },
    {
      key: "regulatorName",
      label: t("docDetails.regulator"),
      type: "text",
    },
    {
      key: "className",
      label: t("docDetails.class"),
      type: "text",
    },
    {
      key: "keywords",
      label: t("docDetails.keywords"),
      type: "tagsInput",
    },
    {
      key: "participants",
      label: t("docDetails.participants"),
      type: "markdown",
    },
    {
      key: "provisions",
      label: t("docDetails.provisions"),
      type: "textarea",
    },
    {
      key: "decision",
      label: t("docDetails.decision"),
      type: "markdown",
    },
  ];

  const setUpdatedText = (newValue: any, field: any) => {
    setEditableData({
      ...editableData,
      [field]: newValue || "",
    });
  };

  return (
    <>
      {fields.map(({ key, label, type }) => (
        <div
          key={key}
          className="mb-2 pb-2 border-b-2 border-gray-500 dark:border-gray-300"
        >
          <h4 className="text-black dark:text-white flex items-center justify-between">
            {label}
            <button
              onClick={() =>
                setEditMode({ ...editMode, [key]: !editMode[key] })
              }
              className="mr-2 text-gray-500 hover:text-gray-700"
            >
              {editMode[key] ? <FaSave /> : <FaEdit />}
            </button>
          </h4>
          {editMode[key] ? (
            <>
              {type === "text" && (
                <input
                  type="text"
                  value={editableData[key]}
                  onChange={(e) => setUpdatedText(e.target.value, key)}
                  className="text-sm text-secondary-color bg-gray-100 dark:bg-gray-700 p-1 rounded w-full"
                />
              )}
              {type === "date" && (
                <CustomDatePicker
                  startDate={new Date(editableData[key])}
                  onChange={(date: any) => onDateChange(date)}
                  isRange={false}
                />
              )}
              {type === "textarea" && (
                <AutoResizeTextarea
                  value={editableData[key]}
                  onChange={(newVal) => setUpdatedText(newVal, key)}
                />
              )}
              {type === "markdown" && (
                <MarkdownEditor
                  data={editableData[key]}
                  onChange={(data: string) => setUpdatedText(data, key)}
                />
              )}
              {type === "tagsInput" && (
                <KeywordsInput
                  keywords={editableData[key]}
                  setKeywords={(newVal: string[]) =>
                    setUpdatedText(newVal, key)
                  }
                />
              )}
            </>
          ) : (
            <>
              {type === "text" || type === "textarea" ? (
                <p className="text-sm text-secondary-color">
                  {editableData[key]}
                </p>
              ) : type === "date" ? (
                <p className="text-sm text-secondary-color">
                  {formatDate(editableData[key])}
                </p>
              ) : key === "keywords" ? (
                <p className="text-sm text-secondary-color">
                  {editableData[key].join(", ")}
                </p>
              ) : (
                <Markdown
                  rehypePlugins={[rehypeRaw]}
                  remarkPlugins={[remarkGfm]}
                  className={styles.markdownBlock + " text-secondary-color"}
                >
                  {editableData[key]}
                </Markdown>
              )}
            </>
          )}
        </div>
      ))}
      <div className="mb-1 pb-2">
        <h4 className="title-base-color">{t("docDetails.link")}</h4>
        <a
          href={editableData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="block text-sm text-secondary-color whitespace-nowrap overflow-hidden text-ellipsis tooltip-text hover:underline"
        >
          {editableData.link}
        </a>
        <Tooltip
          anchorSelect={`.tooltip-text`}
          place="top-end"
          className="text-xs font-light"
          opacity={0.5}
          content={editableData.link}
        />
      </div>
    </>
  );
};

export { InfoTab };
