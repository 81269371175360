import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface SearchInputProps {
  onInputChange: (inputValue: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({ onInputChange }) => {
  const { t } = useTranslation();
  const [valueInput, setValueInput] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isValidValue(val)) setValueInput(val);
  };

  const isValidValue = (val: string) => {
    if (val.length > 1000) {
      alert("Input is too long");
      return false;
    }
    return true;
  };

  const onValueSelected = () => {
    onInputChange(valueInput);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && valueInput) onValueSelected();
  };

  const handleIconClick = () => {
    if (valueInput) onValueSelected();
  };

  return (
    <div className="relative shadow-xl flex-1">
      <span
        className="absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
        onClick={handleIconClick}
      >
        <FaSearch className="text-del-green" />
      </span>
      <input
        type="text"
        value={valueInput}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className="pl-8 p-2 w-full border border-gray-300 dark:border-gray-600 shadow-sm focus:outline-none text-gray-500 dark:text-gray-300 bg-base-color"
        placeholder={t("searchPlaceholder")}
      />
    </div>
  );
};

export { SearchInput };
