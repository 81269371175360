import { axiosApi } from "../../../api/dbAxios";

export const fetchItemDetails = async (
  regulator: string | undefined,
  id: string | undefined,
) => {
  try {
    const response = await axiosApi.get(`/items/${regulator}/${id}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching item details:", error);
    return {};
  }
};
