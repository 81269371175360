import React, { useEffect, useState } from "react";
import Select, { ActionMeta, MultiValue } from "react-select";
import { useTranslation } from "react-i18next";

interface ISearchMultiSelectProps {
  data: { value: string; label: string }[];
  values: any;
  fieldName: string;
  onChange: (
    newValue: MultiValue<{ value: string; label: string }>,
    actionMeta: ActionMeta<{ value: string; label: string }>,
  ) => void;
}

const SearchMultiSelect: React.FC<ISearchMultiSelectProps> = ({
  data,
  values,
  fieldName,
  onChange,
}) => {
  const { t } = useTranslation();
  const [darkTheme, setDarkTheme] = useState(
    localStorage.getItem("darkMode") === "true",
  );

  useEffect(() => {
    setDarkTheme(localStorage.getItem("darkMode") === "true");
  }, [localStorage.getItem("darkMode")]);

  const customStyles = {
    control: (base: any) => ({
      ...base,
      backgroundColor: darkTheme ? "bg-gray-800" : "#fff",
      borderColor: darkTheme ? "rgb(107 114 128)" : "rgb(107 114 128)",
      color: darkTheme ? "#e2e8f0" : "#2d3748",
      borderRadius: "none",
    }),
    input: (base: any) => ({
      ...base,
      color: darkTheme ? "#e2e8f0" : "#2d3748",
    }),
    menu: (base: any) => ({
      ...base,
      backgroundColor: darkTheme ? "#2d3748" : "#fff",
      marginTop: 0,
    }),
    option: (base: any, state: { isFocused: boolean }) => ({
      ...base,
      backgroundColor: state.isFocused
        ? darkTheme
          ? "#4a5568"
          : "#edf2f7"
        : darkTheme
          ? "#2d3748"
          : "#fff",
      color: darkTheme ? "#e2e8f0" : "#2d3748",
      ":active": {
        backgroundColor: darkTheme ? "#4a5568" : "#e2e8f7",
      },
    }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor:
        fieldName === "keywords"
          ? darkTheme
            ? "#43B02A"
            : "#26890D"
          : fieldName === "tags"
            ? darkTheme
              ? "#00A3E0"
              : "#007CB0"
            : base.backgroundColor,
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: "#e2e8f0",
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: darkTheme ? "#e2e8f0" : "#2d3748",
      ":hover": {
        backgroundColor: darkTheme ? "#718096" : "#cbd5e0",
        color: darkTheme ? "#1a202c" : "#2d3748",
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      color: darkTheme ? "#9ca3af" : "#9CA3AF",
    }),
  };

  return (
    <Select
      isMulti
      options={data}
      value={data.filter((option) => values.includes(option.value))}
      onChange={onChange}
      placeholder={
        fieldName === "keywords"
          ? t("filtersSection.searchAndSelectKeywords")
          : t("filtersSection.searchAndSelectTags")
      }
      className={fieldName === "keywords" ? "mb-2" : ""}
      styles={customStyles}
    />
  );
};

export { SearchMultiSelect };
