import React from "react";
import { FaTimes } from "react-icons/fa";

interface ChipProps {
  label: string;
  onDelete: () => void;
}

const Chip: React.FC<ChipProps> = ({ label, onDelete }) => {
  return (
    <div className="flex items-center bg-gray-300 dark:bg-gray-700 rounded-full px-3 py-1 text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
      {label}
      <FaTimes
        className="ml-2 cursor-pointer text-gray-500 dark:text-gray-300 hover:text-red-500 dark:hover:text-red-500"
        onClick={onDelete}
      />
    </div>
  );
};

export { Chip };
