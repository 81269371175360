import React, { useEffect, useRef } from "react";

interface IProps {
  value: string;
  onChange: (newVal: string) => void;
}

const AutoResizeTextarea: React.FC<IProps> = ({ value, onChange }) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleResize = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    handleResize();
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
        handleResize();
      }}
      className="text-sm text-secondary-color bg-gray-100 dark:bg-gray-700 p-2 rounded w-full border border-gray-300 dark:border-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
      style={{ resize: "none", overflow: "hidden" }}
    />
  );
};

export { AutoResizeTextarea };
