import { axiosApi } from "../../../../../../../api/dbAxios";
import { IMessage } from "../interfaces/Message";

export const getChatResponseData = async (
  regulator: string | undefined,
  id: string | undefined,
  messages: IMessage[],
) => {
  try {
    const response = await axiosApi.post(
      `/items/${regulator}/${id}/chat/`,
      messages,
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
