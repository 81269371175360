import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: "Welcome to our website!",
        button: "Switch Language",
        tabs: {
          all: "All",
          important: "Important",
          favourite: "Favourite",
        },
        itemsPerPage: "Items per page:",
        chatButton: "Chat",
        searchButton: "Search",
        aiLabel: "AI",
        darkMode: {
          light: "Light Mode",
          dark: "Dark Mode",
        },
        logOut: "Log Out",
        switchLanguage: "Switch Language",
        pagination: {
          next: "Next",
          previous: "Previous",
        },
        searchPlaceholder: "Search...",
        filtersSection: {
          filters: "Filters",
          clearFilters: "Clear filters",
          country: "Country",
          regulatorName: "Regulator Name",
          dateOfPublication: "Date of Publication",
          keywordsAndTags: "Keywords and tags",
          dateOfIssue: "Date of Issue",
          class: "Class",
          setYesterdaysDate: "Set Yesterday's Date",
          fromToPlaceholder: "From - To",
          searchAndSelectKeywords: "Search and select keywords...",
          searchAndSelectTags: "Search and select tags...",
          from: "from:",
          until: "until:",
        },
        tooltips: {
          remove: "Remove",
          addTag: "Add tag",
          openOriginalDocument: "Open original document",
        },
        docInfo: {
          dateOfPublication: "Date of Publication:",
          regulators: "Regulators:",
          shortSummary: "Short Summary:",
          class: "Class:",
        },
        chatAI: {
          askLegalQuestion: "Ask a legal question...",
          summaryDeleted: "The Summary is unattached from the context",
          howMayIHelp: "How may I help you?",
          summaryAdded: "The Summary is attached to the context",
          unAttach: "Unattach",
          savedPrompts: "Saved Prompts",
          outputAdjustments: "Output adjustments",
          tone: "Tone",
          professional: "Professional",
          casual: "Casual",
          simple: "Simple",
          format: "Format",
          paragraph: "Paragraph",
          email: "E-mail",
          ideas: "Ideas",
          article: "Article",
          length: "Length",
          short: "Short",
          medium: "Medium",
          long: "Long",
        },
        pdfViewer: {
          failedToLoad: "Failed to load document",
          failedToInitialize: "Failed to initialize PDF viewer",
        },
        docDetails: {
          information: "Information",
          summary: "Summary",
          chatAI: "Chat",
          caseName: "Case name",
          dateOfPublication: "Date of publication",
          regulator: "Regulator",
          class: "Class",
          keywords: "Keywords",
          participants: "Participants",
          provisions: "Provisions",
          decision: "Decision",
          link: "Link",
          shortSummary: "Short Summary",
          copyToChatAI: "Copy to Chat AI",
          loadMore: "Load more",
          noResultsFound: "No results found",
        },
      },
    },
    pl: {
      translation: {
        welcome: "Witamy na naszej stronie!",
        button: "Zmień język",
        tabs: {
          all: "Wszystkie",
          important: "Ważne",
          favourite: "Ulubione",
        },
        itemsPerPage: "Ilość na stronę:",
        chatButton: "Czat",
        searchButton: "Szukaj",
        aiLabel: "AI",
        darkMode: {
          light: "Tryb Jasny",
          dark: "Tryb Ciemny",
        },
        logOut: "Wyloguj się",
        switchLanguage: "Zmień język",
        pagination: {
          next: "Następna",
          previous: "Poprzednia",
        },
        searchPlaceholder: "Szukaj...",
        filtersSection: {
          filters: "Filtry",
          clearFilters: "Wyczyść filtry",
          country: "Kraj",
          regulatorName: "Nazwa regulatora",
          dateOfPublication: "Data publikacji",
          keywordsAndTags: "Słowa kluczowe i tagi",
          dateOfIssue: "Data wydania",
          class: "Klasa",
          setYesterdaysDate: "Ustaw datę wczorajszą",
          fromToPlaceholder: "Od - Do",
          searchAndSelectKeywords: "Szukaj i wybierz słowa kluczowe...",
          searchAndSelectTags: "Szukaj i wybierz tagi...",
          from: "od:",
          until: "do:",
        },
        tooltips: {
          remove: "Usuń",
          addTag: "Dodaj tag",
          openOriginalDocument: "Otwórz oryginalny dokument",
        },
        docInfo: {
          dateOfPublication: "Data publikacji:",
          regulators: "Regulatorzy:",
          shortSummary: "Krótki opis:",
          class: "Klasa:",
        },
        chatAI: {
          askLegalQuestion: "Zadaj pytanie prawne...",
          summaryDeleted: "Podsumowanie jest oderwane od kontekstu",
          howMayIHelp: "Jak mogę ci pomóc?",
          summaryAdded: "Podsumowanie jest dołączone do kontekstu",
          unAttach: "Odłącz",
          savedPrompts: "Zapisane podpowiedzi",
          outputAdjustments: "Dostosowanie wyniku",
          tone: "Ton",
          professional: "Profesjonalny",
          casual: "Swobodny",
          simple: "Prosty",
          format: "Format",
          paragraph: "Akapit",
          email: "E-mail",
          ideas: "Pomysły",
          article: "Artykuł",
          length: "Długość",
          short: "Krótki",
          medium: "Średni",
          long: "Długi",
        },
        pdfViewer: {
          failedToLoad: "Nie udało się załadować dokumentu",
          failedToInitialize: "Nie udało się zainicjować przeglądarki PDF",
        },
        docDetails: {
          information: "Informacje",
          summary: "Streszczenie",
          chatAI: "Czat",
          caseName: "Nazwa sprawy",
          dateOfPublication: "Data publikacji",
          regulator: "Regulator",
          class: "Klasa",
          keywords: "Słowa kluczowe",
          participants: "Uczestnicy",
          provisions: "Przepisy",
          decision: "Decyzja",
          link: "Link",
          shortSummary: "Krótki opis",
          copyToChatAI: "Skopiuj do Chat AI",
          loadMore: "Załaduj więcej",
          noResultsFound: "Nie znaleziono wyników",
        },
      },
    },
    cs: {
      translation: {
        welcome: "Vítejte na našich stránkách!",
        button: "Změnit jazyk",
        tabs: {
          all: "Vše",
          important: "Důležité",
          favourite: "Oblíbené",
        },
        itemsPerPage: "Počet položek na stránku:",
        chatButton: "Chat",
        searchButton: "Hledat",
        aiLabel: "AI",
        darkMode: {
          light: "Světlý režim",
          dark: "Tmavý režim",
        },
        logOut: "Odhlásit se",
        switchLanguage: "Změnit jazyk",
        pagination: {
          next: "Další",
          previous: "Předchozí",
        },
        searchPlaceholder: "Hledat...",
        filtersSection: {
          filters: "Filtry",
          clearFilters: "Vymazat filtry",
          country: "Země",
          regulatorName: "Název regulátora",
          dateOfPublication: "Datum publikace",
          keywordsAndTags: "Klíčová slova a značky",
          dateOfIssue: "Datum vydání",
          class: "Třída",
          setYesterdaysDate: "Nastavit včerejší datum",
          fromToPlaceholder: "Od - Do",
          searchAndSelectKeywords: "Vyhledejte a vyberte klíčová slova...",
          searchAndSelectTags: "Vyhledejte a vyberte značky...",
          from: "od:",
          until: "do:",
        },
        tooltips: {
          remove: "Odstranit",
          addTag: "Přidat značku",
          openOriginalDocument: "Otevřít původní dokument",
        },
        docInfo: {
          dateOfPublication: "Datum publikace:",
          regulators: "Regulátoři:",
          shortSummary: "Stručné shrnutí:",
          class: "Třída:",
        },
        chatAI: {
          askLegalQuestion: "Zeptejte se na právní otázku...",
          summaryDeleted: "Souhrn je oddělen od kontextu",
          howMayIHelp: "Jak vám mohu pomoci?",
          summaryAdded: "Shrnutí je připojeno ke kontextu",
          unAttach: "Odpojit",
          savedPrompts: "Uložené výzvy",
          outputAdjustments: "Úpravy výstupu",
          tone: "Tón",
          professional: "Profesionální",
          casual: "Neformální",
          simple: "Jednoduchý",
          format: "Formát",
          paragraph: "Odstavec",
          email: "E-mail",
          ideas: "Nápady",
          article: "Článek",
          length: "Délka",
          short: "Krátká",
          medium: "Střední",
          long: "Dlouhá",
        },
        pdfViewer: {
          failedToLoad: "Nepodařilo se načíst dokument",
          failedToInitialize: "Nepodařilo se inicializovat prohlížeč PDF",
        },
        docDetails: {
          information: "Informace",
          summary: "Shrnutí",
          chatAI: "Chat",
          caseName: "Název případu",
          dateOfPublication: "Datum publikace",
          regulator: "Regulátor",
          class: "Třída",
          keywords: "Klíčová slova",
          participants: "Účastníci",
          provisions: "Ustanovení",
          decision: "Rozhodnutí",
          link: "Odkaz",
          shortSummary: "Stručné shrnutí",
          copyToChatAI: "Zkopírovat do Chat AI",
          loadMore: "Načíst více",
          noResultsFound: "Nenalezeny žádné výsledky",
        },
      },
    },
    ro: {
      translation: {
        welcome: "Bun venit pe site-ul nostru!",
        button: "Schimbați limba",
        tabs: {
          all: "Toate",
          important: "Important",
          favourite: "Preferate",
        },
        itemsPerPage: "Elemente pe pagină:",
        chatButton: "Chat",
        searchButton: "Căutare",
        aiLabel: "AI",
        darkMode: {
          light: "Mod Luminos",
          dark: "Mod Întunecat",
        },
        logOut: "Deconectare",
        switchLanguage: "Schimbați limba",
        pagination: {
          next: "Următorul",
          previous: "Precedentul",
        },
        searchPlaceholder: "Căutare...",
        filtersSection: {
          filters: "Filtre",
          clearFilters: "Șterge filtrele",
          country: "Țară",
          regulatorName: "Nume Regulator",
          dateOfPublication: "Data Publicării",
          keywordsAndTags: "Cuvinte cheie și etichete",
          dateOfIssue: "Data Emiterii",
          class: "Clasă",
          setYesterdaysDate: "Setați data de ieri",
          fromToPlaceholder: "De la - Până la",
          searchAndSelectKeywords: "Căutați și selectați cuvinte cheie...",
          searchAndSelectTags: "Căutați și selectați etichete...",
          from: "de la:",
          until: "până la:",
        },
        tooltips: {
          remove: "Elimină",
          addTag: "Adaugă etichetă",
          openOriginalDocument: "Deschide documentul original",
        },
        docInfo: {
          dateOfPublication: "Data Publicării:",
          regulators: "Regulatori:",
          shortSummary: "Scurt rezumat:",
          class: "Clasă:",
        },
        chatAI: {
          askLegalQuestion: "Pune o întrebare juridică...",
          summaryDeleted: "Rezumatul este detașat de context",
          howMayIHelp: "Cu ce vă pot ajuta?",
          summaryAdded: "Rezumatul este atașat contextului",
          unAttach: "Detașează",
          savedPrompts: "Indicații salvate",
          outputAdjustments: "Ajustări de ieșire",
          tone: "Ton",
          professional: "Profesional",
          casual: "Lejer",
          simple: "Simplu",
          format: "Format",
          paragraph: "Paragraf",
          email: "E-mail",
          ideas: "Idei",
          article: "Articol",
          length: "Lungime",
          short: "Scurt",
          medium: "Mediu",
          long: "Lung",
        },
        pdfViewer: {
          failedToLoad: "Eșec la încărcarea documentului",
          failedToInitialize: "Eșec la inițializarea vizualizatorului PDF",
        },
        docDetails: {
          information: "Informații",
          summary: "Rezumat",
          chatAI: "Chat",
          caseName: "Numele cazului",
          dateOfPublication: "Data Publicării",
          regulator: "Regulator",
          class: "Clasă",
          keywords: "Cuvinte cheie",
          participants: "Participanți",
          provisions: "Prevederi",
          decision: "Decizie",
          link: "Link",
          shortSummary: "Scurt rezumat",
          copyToChatAI: "Copiați în Chat AI",
          loadMore: "Încarcă mai mult",
          noResultsFound: "Nu s-au găsit rezultate",
        },
      },
    },
    hu: {
      translation: {
        welcome: "Üdvözöljük a weboldalunkon!",
        button: "Nyelv váltása",
        tabs: {
          all: "Összes",
          important: "Fontos",
          favourite: "Kedvenc",
        },
        itemsPerPage: "Elemek száma oldalanként:",
        chatButton: "Chat",
        searchButton: "Keresés",
        aiLabel: "AI",
        darkMode: {
          light: "Világos mód",
          dark: "Sötét mód",
        },
        logOut: "Kijelentkezés",
        switchLanguage: "Nyelv váltása",
        pagination: {
          next: "Következő",
          previous: "Előző",
        },
        searchPlaceholder: "Keresés...",
        filtersSection: {
          filters: "Szűrők",
          clearFilters: "Szűrők törlése",
          country: "Ország",
          regulatorName: "Szabályozó neve",
          dateOfPublication: "Közzététel dátuma",
          keywordsAndTags: "Kulcsszavak és címkék",
          dateOfIssue: "Kiadás dátuma",
          class: "Osztály",
          setYesterdaysDate: "Tegnap dátumának beállítása",
          fromToPlaceholder: "-tól -ig",
          searchAndSelectKeywords: "Kulcsszavak keresése és kiválasztása...",
          searchAndSelectTags: "Címkék keresése és kiválasztása...",
          from: "-tól:",
          until: "-ig:",
        },
        tooltips: {
          remove: "Eltávolítás",
          addTag: "Címke hozzáadása",
          openOriginalDocument: "Eredeti dokumentum megnyitása",
        },
        docInfo: {
          dateOfPublication: "Közzététel dátuma:",
          regulators: "Szabályozók:",
          shortSummary: "Rövid összefoglaló:",
          class: "Osztály:",
        },
        chatAI: {
          askLegalQuestion: "Tegyen fel jogi kérdést...",
          summaryDeleted: "Az összefoglaló el van választva a kontextustól",
          howMayIHelp: "Hogyan segíthetek?",
          summaryAdded: "Az összefoglaló a kontextushoz van csatolva",
          unAttach: "Leválaszt",
          savedPrompts: "Elmentett kérések",
          outputAdjustments: "Kimeneti beállítások",
          tone: "Hangnem",
          professional: "Professzionális",
          casual: "Kötetlen",
          simple: "Egyszerű",
          format: "Formátum",
          paragraph: "Bekezdés",
          email: "E-mail",
          ideas: "Ötletek",
          article: "Cikk",
          length: "Hossz",
          short: "Rövid",
          medium: "Közepes",
          long: "Hosszú",
        },
        pdfViewer: {
          failedToLoad: "Nem sikerült betölteni a dokumentumot",
          failedToInitialize: "Nem sikerült inicializálni a PDF megjelenítőt",
        },
        docDetails: {
          information: "Információ",
          summary: "Összefoglaló",
          chatAI: "Chat",
          caseName: "Ügy neve",
          dateOfPublication: "Közzététel dátuma",
          regulator: "Szabályozó",
          class: "Osztály",
          keywords: "Kulcsszavak",
          participants: "Résztvevők",
          provisions: "Rendelkezések",
          decision: "Döntés",
          link: "Link",
          shortSummary: "Rövid összefoglaló",
          copyToChatAI: "Másolás a Chat AI-hez",
          loadMore: "Töltsön be többet",
          noResultsFound: "Nincs találat",
        },
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
