import React, { useState, useEffect } from "react";
import { CustomDatePicker } from "./components/CustomDatePicker/CustomDatePicker";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { SearchMultiSelect } from "./components/SearchMultiSelect/SearchMultiSelect";
import { MultiValue } from "react-select";
import { useTranslation } from "react-i18next";
import { FilterState } from "../../interfaces/FilterState";

interface IFiltersProps {
  filtersData: {
    keywords: string[];
    tags: string[];
    country: string[];
    regulator: string[];
    class_name: string[];
  };
  tags: string[];
  filters: FilterState;
  onFiltersChange: (updatedFilters: IFiltersProps["filters"]) => void;
}

const Filters: React.FC<IFiltersProps> = ({
  filtersData,
  tags,
  filters,
  onFiltersChange,
}) => {
  const { t } = useTranslation();
  const [isCountryOpen, setIsCountryOpen] = useState(true);
  const [isRegulatorOpen, setIsRegulatorOpen] = useState(true);
  const [isPublicationDateOpen, setIsPublicationDateOpen] = useState(true);
  const [isIssueDateOpen, setIsIssueDateOpen] = useState(true);
  const [isClassOpen, setIsClassOpen] = useState(true);
  const [isKeywordOpen, setIsKeywordOpen] = useState(true);
  const [keywordValue, setKeywordValue] = useState<string[]>(filters.keywords);
  const [tagValue, setTagValue] = useState<string[]>(filters.tags);
  const [regulatorValue, setRegulatorValue] = useState<string[]>(
    filters.regulator,
  );
  const [countryValue, setCountryValue] = useState<string[]>(filters.country);
  const [classValue, setClassValue] = useState<string[]>(filters.class_name);
  const [publicationDateValue, setPublicationDateValue] = useState<
    [Date | any, Date | any]
  >([filters.date_from, filters.date_to]);
  const [issueDateValue, setIssueDateValue] = useState<
    [Date | any, Date | any]
  >([filters.date_issue_from, filters.date_issue_to]);

  const keywordsData = Array.from(
    new Set(filtersData.keywords ? [...filtersData.keywords] : []),
  ).map((keyword) => ({
    value: keyword,
    label: keyword,
  }));
  const tagsData = Array.from(
    new Set(filtersData.tags ? [...filtersData.tags, ...tags] : [...tags]),
  ).map((tag) => ({
    value: tag,
    label: tag,
  }));

  useEffect(() => {
    setTagValue(filters.tags);
    setKeywordValue(filters.keywords);
    setCountryValue(filters.country);
    setRegulatorValue(filters.regulator);
    setClassValue(filters.class_name);
    setPublicationDateValue([filters.date_from, filters.date_to]);
    setIssueDateValue([filters.date_issue_from, filters.date_issue_to]);
  }, [filters]);

  const clearAllFilters = () => {
    setTagValue([]);
    setKeywordValue([]);
    setCountryValue([]);
    setRegulatorValue([]);
    setClassValue([]);
    setPublicationDateValue([null, null]);
    setIssueDateValue([null, null]);
    onFiltersChange({
      ...filters,
      keywords: [],
      tags: [],
      country: [],
      regulator: [],
      class_name: [],
      date_from: null,
      date_to: null,
      date_issue_from: null,
      date_issue_to: null,
    });
  };

  const handleDateChange = (
    dates: [Date | null, Date | null],
    isIssueDate = false,
  ) => {
    if (dates[0] && dates[1] && dates[0].getTime() === dates[1].getTime()) {
      dates[1] = null;
    }
    if (isIssueDate) {
      setIssueDateValue(dates);
      onFiltersChange({
        ...filters,
        date_issue_from: dates[0],
        date_issue_to: dates[1],
      });
    } else {
      setPublicationDateValue(dates);
      onFiltersChange({
        ...filters,
        date_from: dates[0],
        date_to: dates[1],
      });
    }
  };

  const handleFilterFieldChange = (
    filterType: keyof IFiltersProps["filters"],
    value: string,
    selectedOptions: MultiValue<{ value: string; label: string }> = [],
  ) => {
    let updatedFilters = { ...filters };

    switch (filterType) {
      case "country":
        const updatedCountry = countryValue.includes(value)
          ? countryValue.filter((c) => c !== value)
          : [...countryValue, value];
        setCountryValue(updatedCountry);
        updatedFilters = { ...updatedFilters, country: updatedCountry };
        break;

      case "regulator":
        let updatedRegulator = regulatorValue.includes(value)
          ? regulatorValue.filter((r) => r !== value)
          : [...regulatorValue, value];
        let updatedClass = classValue;

        if (updatedRegulator.length === 0) {
          updatedClass = [];
          setIsClassOpen(true);
          setIsIssueDateOpen(true);
          setClassValue(updatedClass);
        }

        setRegulatorValue(updatedRegulator);
        updatedFilters = {
          ...updatedFilters,
          regulator: updatedRegulator,
          class_name: updatedClass,
        };
        break;

      case "class_name":
        const updatedClassValue = classValue.includes(value)
          ? classValue.filter((c) => c !== value)
          : [...classValue, value];
        setClassValue(updatedClassValue);
        updatedFilters = { ...updatedFilters, class_name: updatedClassValue };
        break;

      case "keywords":
        const updatedKeywords = selectedOptions.map(
          (option: any) => option.value,
        );
        setKeywordValue(updatedKeywords);
        updatedFilters = { ...updatedFilters, keywords: updatedKeywords };
        break;

      case "tags":
        const updatedTags = selectedOptions.map((option: any) => option.value);
        setTagValue(updatedTags);
        updatedFilters = { ...updatedFilters, tags: updatedTags };
        break;

      default:
        break;
    }

    onFiltersChange(updatedFilters);
  };

  const hasActiveFilters =
    tagValue.length > 0 ||
    keywordValue.length > 0 ||
    countryValue.length > 0 ||
    regulatorValue.length > 0 ||
    classValue.length > 0 ||
    publicationDateValue[0] !== null ||
    publicationDateValue[1] !== null ||
    issueDateValue[0] !== null ||
    issueDateValue[1] !== null;

  const isRegionalCourtSelected = regulatorValue.includes(
    "Regional Courts of the Czech Republic",
  );

  return (
    <div className="p-4 bg-base-color rounded-lg">
      <div className="flex justify-between items-center border-b-2 pb-2 mb-2 dark:border-gray-700">
        <h3 className="font-semibold text-lg dark:text-gray-300">
          {t("filtersSection.filters")}
        </h3>
        {hasActiveFilters && (
          <button
            onClick={clearAllFilters}
            className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer dark:text-gray-300"
          >
            {t("filtersSection.clearFilters")}
          </button>
        )}
      </div>

      <div className="mb-2 pb-2 border-b dark:border-gray-700">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold mb-2 dark:text-gray-300">
            {t("filtersSection.country")}
          </h4>
          <button
            onClick={() => setIsCountryOpen(!isCountryOpen)}
            className={`text-sm transform transition-transform duration-300 ${
              isCountryOpen ? "rotate-180" : "rotate-0"
            } hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer`}
          >
            <FiChevronDown size={20} className="dark:text-gray-400" />
          </button>
        </div>
        <div
          className={`${
            isCountryOpen ? "max-h-[1000px]" : "max-h-0"
          } filter-transition`}
        >
          {filtersData?.country?.map((country) => (
            <label
              key={country}
              className="flex text-sm items-start mb-2 text-base-color hover-btn-del-green"
            >
              <input
                type="checkbox"
                checked={countryValue.includes(country)}
                onChange={() => handleFilterFieldChange("country", country)}
                className="mr-1 mt-0.5 ml-3 accent-del-light-theme-green dark:accent-del-green"
              />
              <span className="leading-tight">{country}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="mb-2 pb-2 border-b dark:border-gray-700">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold mb-2 dark:text-gray-300">
            {t("filtersSection.regulatorName")}
          </h4>
          <button
            onClick={() => setIsRegulatorOpen(!isRegulatorOpen)}
            className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer"
          >
            {isRegulatorOpen ? (
              <FiChevronUp size={20} className="dark:text-gray-400" />
            ) : (
              <FiChevronDown size={20} className="dark:text-gray-400" />
            )}
          </button>
        </div>
        <div
          className={`${
            isRegulatorOpen ? "max-h-[1000px]" : "max-h-0"
          } filter-transition`}
        >
          <div>
            {filtersData?.regulator?.map((regulator) => (
              <label
                key={regulator}
                className="flex text-sm items-start mb-2 text-base-color dark:hover:text-del-green hover:text-del-light-theme-green"
              >
                <input
                  type="checkbox"
                  checked={regulatorValue.includes(regulator)}
                  onChange={() =>
                    handleFilterFieldChange("regulator", regulator)
                  }
                  className="mr-1 mt-0.5 ml-3 accent-del-light-theme-green dark:accent-del-green"
                />
                <span className="leading-tight">{regulator}</span>
              </label>
            ))}
          </div>
        </div>
      </div>

      <div className="mb-2 pb-2 border-b dark:border-gray-700">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold mb-2 dark:text-gray-300">
            {t("filtersSection.dateOfPublication")}
          </h4>
          <button
            onClick={() => setIsPublicationDateOpen(!isPublicationDateOpen)}
            className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer"
          >
            {isPublicationDateOpen ? (
              <FiChevronUp size={20} className="dark:text-gray-400" />
            ) : (
              <FiChevronDown size={20} className="dark:text-gray-400" />
            )}
          </button>
        </div>
        <div
          className={`${
            isPublicationDateOpen ? "max-h-[1000px] no-overflow" : "max-h-0"
          } filter-transition`}
        >
          <div>
            <CustomDatePicker
              startDate={publicationDateValue[0]}
              endDate={publicationDateValue[1]}
              onChange={(date) => handleDateChange(date)}
              onClear={() => handleDateChange([null, null])}
              isRange={true}
            />
          </div>
        </div>
      </div>

      <div className="mb-2 pb-2 border-b dark:border-gray-700">
        <div className="flex justify-between items-center">
          <h4 className="font-semibold mb-2 dark:text-gray-300">
            {t("filtersSection.keywordsAndTags")}
          </h4>
          <button
            onClick={() => setIsKeywordOpen(!isKeywordOpen)}
            className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer"
          >
            {isKeywordOpen ? (
              <FiChevronUp size={20} className="dark:text-gray-400" />
            ) : (
              <FiChevronDown size={20} className="dark:text-gray-400" />
            )}
          </button>
        </div>
        <div
          className={`${
            isKeywordOpen ? "max-h-[1000px] no-overflow" : "max-h-0"
          } filter-transition`}
        >
          <>
            <SearchMultiSelect
              data={keywordsData}
              values={keywordValue}
              fieldName="keywords"
              onChange={(selectedOptions) =>
                handleFilterFieldChange("keywords", "", selectedOptions)
              }
            />
            <SearchMultiSelect
              data={tagsData}
              values={tagValue}
              fieldName="tags"
              onChange={(selectedOptions) =>
                handleFilterFieldChange("tags", "", selectedOptions)
              }
            />
          </>
        </div>
      </div>

      {isRegionalCourtSelected && (
        <div className="mb-2 pb-2 border-b dark:border-gray-700">
          <div className="flex justify-between items-center">
            <h4 className="font-semibold mb-2 dark:text-gray-300">
              {t("filtersSection.dateOfIssue")}
            </h4>
            <button
              onClick={() => setIsIssueDateOpen(!isIssueDateOpen)}
              className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer"
            >
              {isIssueDateOpen ? (
                <FiChevronUp size={20} className="dark:text-gray-400" />
              ) : (
                <FiChevronDown size={20} className="dark:text-gray-400" />
              )}
            </button>
          </div>
          <div
            className={`${
              isIssueDateOpen ? "max-h-[1000px] no-overflow" : "max-h-0"
            } filter-transition`}
          >
            <div>
              <CustomDatePicker
                startDate={issueDateValue[0]}
                endDate={issueDateValue[1]}
                onChange={(date) => handleDateChange(date, true)}
                onClear={() => handleDateChange([null, null], true)}
                isRange={true}
              />
            </div>
          </div>
        </div>
      )}

      {regulatorValue.length > 0 && (
        <div className="mb-2 pb-2 border-b dark:border-gray-700">
          <div className="flex justify-between items-center">
            <h4 className="font-semibold mb-2 text-lg dark:text-gray-300">
              {t("filtersSection.class")}
            </h4>
            <button
              onClick={() => setIsClassOpen(!isClassOpen)}
              className="text-sm hover:text-gray-500 dark:hover:text-gray-400 cursor-pointer"
            >
              {isClassOpen ? (
                <FiChevronUp size={20} className="dark:text-gray-400" />
              ) : (
                <FiChevronDown size={20} className="dark:text-gray-400" />
              )}
            </button>
          </div>
          <div
            className={`${
              isClassOpen ? "max-h-[1000px]" : "max-h-0"
            } filter-transition`}
          >
            <div>
              {filtersData.class_name.map((classItem) => (
                <label
                  key={classItem}
                  className="flex items-start mb-2 text-base-color hover-btn-del-green"
                >
                  <input
                    type="checkbox"
                    checked={classValue.includes(classItem.replace(/'/g, ""))}
                    onChange={() =>
                      handleFilterFieldChange(
                        "class_name",
                        classItem.replace(/'/g, ""),
                      )
                    }
                    className="mr-1 mt-0.5 ml-3 accent-del-light-theme-green dark:accent-del-green"
                  />
                  <span className="leading-tight">
                    {classItem.replace(/'/g, "")}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { Filters };
