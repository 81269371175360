import { axiosApi } from "../../../../../api/dbAxios";
import { IMessage, IUsedIds } from "../interfaces/message";

export const getChatResponseData = async (
  usedIds: IUsedIds[] | string[],
  messages: IMessage[],
) => {
  try {
    const body = {
      use_ids: usedIds,
      conversation: messages,
      type: "details_search",
    };
    const response = await axiosApi.post(`/chat/`, body);
    return response.data;
  } catch (error) {
    return {};
  }
};
