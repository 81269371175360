import { IDocDetails } from "../../../interfaces/IDocDetails";

export const cleanMarkdownData = (data: IDocDetails): IDocDetails => {
  return Object.entries(data).reduce(
    (acc: any, [key, value]: [string, string]): IDocDetails => {
      if (typeof value === "string") {
        acc[key as keyof IDocDetails] = value.replace(
          /```markdown\s*|```\s*/g,
          "",
        ) as string;
      } else {
        acc[key as keyof IDocDetails] = value;
      }
      return acc;
    },
    {} as IDocDetails,
  );
};
