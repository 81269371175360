import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { DetailsPage } from "./pages/DetailsPage/DetailsPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HomePage } from "./pages/HomePage/HomePage";

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const queryClient = new QueryClient();

  const handleToggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", JSON.stringify(newMode));
      return newMode;
    });
  };

  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    if (savedMode !== null) {
      setDarkMode(JSON.parse(savedMode));
    }
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const handleDocTypeSelected = (selectedValue: string) => {
    console.log("DocType value:", selectedValue);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="flex flex-col min-h-screen">
          <Header
            onDocTypeChange={handleDocTypeSelected}
            darkMode={darkMode}
            setDarkMode={handleToggleDarkMode}
          />
          <div className="flex-grow flex flex-col justify-between p-4">
            <Routes>
              <Route path="/" element={<Navigate to="/recent" />} />
              <Route path="*" element={<Navigate to="/recent" />} />
              <Route path="/recent" element={<HomePage />} />
              <Route path="/details/:regulator/:id" element={<DetailsPage />} />
              <Route path="/chatAI" element={<HomePage />} />
            </Routes>
          </div>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
