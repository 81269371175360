import React, { useState, useEffect, useRef } from "react";
import { FaArrowUp } from "react-icons/fa";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { ModalOutputAdjustments } from "./components/ModalOutputAdjustments/ModalOutputAdjustments";
import { RxDoubleArrowDown, RxDoubleArrowUp } from "react-icons/rx";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { LuTextSelect } from "react-icons/lu";
import { SummaryMessage } from "./components/SummaryMessage/SummaryMessage";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { getChatResponseData } from "./services/chatResponse.service";
import { useParams } from "react-router-dom";
import { IOutputAdjustment } from "./interfaces/OutputAdjustment";
import { PiBroom } from "react-icons/pi";
import { IMessage } from "./interfaces/Message";

interface IChatAIProps {
  summary: string;
  onRemoveSummaryBinding: (summary: string) => void;
}

const ChatAI: React.FC<IChatAIProps> = ({
  summary,
  onRemoveSummaryBinding,
}) => {
  const { t } = useTranslation();
  const [valueInput, setValueInput] = useState<string>("");
  const [valueConversationInput, setValueConversationInput] =
    useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [waitingMessage, setWaitingMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [conversationHistory, setConversationHistory] = useState<IMessage[]>(
    [],
  );
  const [isSummaryBound, setIsSummaryBound] = useState(false);
  const [isSummaryDeleted, setIsSummaryDeleted] = useState(false);
  const { regulator, id } = useParams<{ regulator: string; id: string }>();
  const [outputAdj, setOutputAdj] = useState<IOutputAdjustment>({
    tone: "Professional",
    format: "Paragraph",
    length: "Short",
  });
  const [isTypingResponse, setIsTypingResponse] = useState(false);

  const {
    mutate: fetchChatResponse,
    isPending,
    data,
  } = useMutation({
    mutationFn: () => getChatResponseData(regulator, id, conversationHistory),
  });

  const getMessagesAfterLastInfo = (allMessages: IMessage[]): IMessage[] => {
    const lastInfoIndex = allMessages
      .map((msg) => msg.role)
      .lastIndexOf("info");
    return lastInfoIndex >= 0
      ? allMessages.slice(lastInfoIndex + 1)
      : allMessages;
  };

  const savedPrompts = [
    "What conditions is mentioned in the document?",
    "What groups or sectors affected?",
    "What is the court's decision?",
    "What parties are mentioned in the document?",
    "What is the subject to the dispute?",
  ];

  const isValidValue = (val: string) => {
    if (val.length > 1000) {
      alert("Input is too long");
      return false;
    }
    return true;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isValidValue(val)) setValueInput(val);
  };

  const handleSubmit = (val: string) => {
    if (val.trim()) {
      fetchChatResponse();
      setIsSubmitted(true);
      setIsModalOpen(false);
      setValueConversationInput("");
      setValueInput("");

      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", text: val },
      ]);

      const filteredMessages = getMessagesAfterLastInfo([
        ...messages,
        { role: "user", text: val },
      ]);

      setConversationHistory(filteredMessages);
    }
  };

  const handleKeyPress = (key: string, val: string) => {
    if (isPending || isTypingResponse) {
      return;
    }
    if (key === "Enter" && val.trim()) handleSubmit(val);
  };

  const handleConvInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (isValidValue(val)) setValueConversationInput(val);
  };

  useEffect(() => {
    if (isPending) {
      let count = 0;
      const interval = setInterval(() => {
        setWaitingMessage(".".repeat((count % 3) + 1));
        count += 1;
      }, 500);

      return () => {
        clearInterval(interval);
      };
    } else if (!isPending) {
      setWaitingMessage("");
      startTypingResponse();
    }
  }, [isPending, data]);

  useEffect(() => {
    if (summary) {
      setIsSubmitted(true);
      setIsSummaryBound(true);
      setIsSummaryDeleted(false);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "summary", text: summary },
      ]);
    }
  }, [summary]);

  const startTypingResponse = () => {
    if (data && data.length > 1) {
      setIsTypingResponse(true);

      let index = 0;
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "assistant", text: "" },
      ]);

      const typingInterval = setInterval(() => {
        setMessages((prevMessages) => {
          const lastMessage = data[data.length - 1];
          if (lastMessage.role === "assistant") {
            const updatedText = lastMessage.text.slice(0, index + 1);
            index += 1;

            if (index === lastMessage.text.length) {
              clearInterval(typingInterval);
              setIsTypingResponse(false);

              return [
                ...prevMessages.slice(0, -1),
                {
                  ...lastMessage,
                  text: data[data.length - 1].text || "",
                  hasSummaryIcon: isSummaryBound,
                },
              ];
            }

            return [
              ...prevMessages.slice(0, -1),
              {
                ...lastMessage,
                text: updatedText,
                hasSummaryIcon: isSummaryBound,
              },
            ];
          }
          return prevMessages;
        });
      }, 10);
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages, waitingMessage]);

  const removeSummaryBinding = () => {
    if (isSummaryDeleted) return;

    setIsSummaryBound(false);
    setIsSummaryDeleted(true);
    onRemoveSummaryBinding("");

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "info",
        text: "chatAI.summaryDeleted",
      },
    ]);
  };

  const clearHistory = () => {
    onRemoveSummaryBinding("");
    setIsSummaryBound(false);
    setIsSummaryDeleted(true);
    setMessages([]);
    setConversationHistory([]);
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-700 shadow-md text-secondary-color h-[calc(100vh-145px)] flex relative">
      <div className="w-full relative transition-all duration-300 flex flex-col border-gray-300">
        {!isSubmitted ? (
          <div className="flex flex-col items-center justify-center h-full bg-white dark:bg-gray-900 text-gray-600 dark:text-white">
            <h1 className="text-3xl font-bold mb-8">
              {t("chatAI.howMayIHelp")}
            </h1>

            <div className="relative w-3/4 mb-10 flex items-center">
              <input
                type="text"
                placeholder={t("chatAI.askLegalQuestion")}
                className="w-full input-chatai-color"
                value={valueInput}
                onChange={handleInputChange}
                onKeyDown={(event) => handleKeyPress(event.key, valueInput)}
              />
              <button
                className="absolute right-12 top-1/2 transform -translate-y-1/2 bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-green text-white"
                onClick={() => handleSubmit(valueInput)}
              >
                <FaArrowUp />
              </button>

              <div className="ml-2">
                <button
                  className="text-xl bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-green text-white"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  <HiOutlineAdjustmentsHorizontal />
                </button>
              </div>

              {isModalOpen && (
                <ModalOutputAdjustments
                  onClose={() => setIsModalOpen(false)}
                  isSubmit={false}
                  onOutputAdjChange={(outputs: IOutputAdjustment) =>
                    setOutputAdj(outputs)
                  }
                  outputAdj={outputAdj}
                />
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full h-full relative">
              <div
                className="flex-grow overflow-y-auto mb-16 space-y-3 pt-4 pr-4"
                ref={chatContainerRef}
              >
                {messages.map((message, index) => (
                  <div
                    key={`message-${index}`}
                    className={`text-gray-800 dark:text-white flex ${
                      message.role === "user"
                        ? "max-w-max p-2 rounded-lg bg-gray-300 dark:bg-gray-500 self-end text-right ml-auto"
                        : message.role === "assistant"
                          ? "max-w-max p-2 rounded-lg bg-gray-400 dark:bg-gray-800 self-start text-left ml-3"
                          : message.role === "info"
                            ? "max-w-max m-auto text-sm"
                            : ""
                    }`}
                  >
                    {message.role !== "summary" ? (
                      message.role !== "info" ? (
                        <span className="flex-grow">{message.text}</span>
                      ) : (
                        t(message.text)
                      )
                    ) : (
                      <SummaryMessage
                        text={message.text}
                        isSummaryDeleted={isSummaryDeleted}
                        onRemoveBinding={removeSummaryBinding}
                      />
                    )}

                    {message.hasSummaryIcon && (
                      <>
                        <LuTextSelect
                          className={`tooltip-context-${index} min-w-6 text-custom-teal dark:text-custom-light-blue cursor-pointer focus:outline-none ml-0.5 mt-0.5`}
                          size={20}
                        />
                        <Tooltip
                          anchorSelect={`.tooltip-context-${index}`}
                          clickable
                        >
                          <div className="flex flex-col items-center">
                            <p className="text-center">
                              {t("chatAI.summaryAdded")}
                            </p>
                            <button
                              className="mt-2 p-1 border border-white bg-red-500 hover:bg-red-600 text-white text-xs rounded"
                              onClick={removeSummaryBinding}
                              disabled={isSummaryDeleted}
                            >
                              {t("chatAI.unAttach")}
                            </button>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                ))}
                {isPending && waitingMessage && (
                  <span className="p-2 bg-gray-400 dark:bg-gray-800 text-gray-800 dark:text-white rounded-lg self-start inline-block ml-3">
                    {waitingMessage}
                  </span>
                )}
              </div>
            </div>
            <div className="absolute bottom-0 right-0 w-full px-2 pb-2 bg-gray-100 dark:bg-gray-700 flex">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="..."
                  className="w-full input-chatai-color"
                  value={valueConversationInput}
                  onChange={handleConvInputChange}
                  onKeyDown={(event) =>
                    handleKeyPress(event.key, valueConversationInput)
                  }
                />
                <button
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-light-theme-green text-white"
                  onClick={() => {
                    if (!isPending && !isTypingResponse)
                      handleSubmit(valueConversationInput);
                  }}
                >
                  <FaArrowUp />
                </button>
              </div>
              <div className="mx-8">
                <button
                  className="absolute top-3 right-1.5 text-xl bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-light-theme-green text-white"
                  onClick={() => setIsModalOpen(!isModalOpen)}
                >
                  <HiOutlineAdjustmentsHorizontal />
                </button>

                <button
                  className="absolute top-3 right-10 text-xl bg-gray-400 dark:bg-gray-500 p-1 rounded-full hover:bg-del-green dark:hover:bg-del-light-theme-green text-white"
                  onClick={() => {
                    if (!isPending && !isTypingResponse) clearHistory();
                  }}
                >
                  <PiBroom />
                </button>
              </div>

              {isModalOpen && (
                <ModalOutputAdjustments
                  onClose={() => setIsModalOpen(false)}
                  isSubmit={true}
                  onOutputAdjChange={(outputs: IOutputAdjustment) =>
                    setOutputAdj(outputs)
                  }
                  outputAdj={outputAdj}
                />
              )}
            </div>
          </>
        )}
      </div>
      {!isSubmitted && (
        <>
          {" "}
          <div
            className={`transition-all duration-300 border-gray-300 w-full absolute -bottom-2 mb-2 ${
              isPanelCollapsed ? "h-0" : "h-3/5 py-2"
            } bg-gray-400 dark:bg-gray-700 text-gray-800 dark:text-white px-4 overflow-hidden`}
          >
            <p className="mb-2 text-lg font-semibold text-center">
              {t("chatAI.savedPrompts")}
            </p>
            <ul className="space-y-2">
              {savedPrompts.map((prompt, index) => (
                <>
                  <li
                    key={`prompt-${index}`}
                    className={`tooltip-${index} bg-gray-300 dark:bg-gray-600 p-2 rounded-lg cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-500 overflow-hidden text-ellipsis whitespace-nowrap max-w-full`}
                    onClick={() => handleSubmit(prompt)}
                  >
                    {prompt}
                  </li>
                  <Tooltip
                    anchorSelect={`.tooltip-${index}`}
                    place="top"
                    content={prompt}
                    className="max-w-full"
                  />
                </>
              ))}
            </ul>
          </div>
          <button
            className="absolute bottom-1 right-1/2 text-xl p-1 title-base-color hover:text-del-green"
            onClick={() => setIsPanelCollapsed(!isPanelCollapsed)}
          >
            {isPanelCollapsed ? <RxDoubleArrowUp /> : <RxDoubleArrowDown />}
          </button>
        </>
      )}
    </div>
  );
};

export { ChatAI };
