import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";
import { format } from "date-fns";
import { IDocDetails } from "../../interfaces/IDocDetails";
import { ChatAI } from "./components/ChatAI/ChatAI";
import { useTranslation } from "react-i18next";
import { cleanMarkdownData } from "./helpers/cleanMarkdownData";
import { InfoTab } from "./components/InfoTab/InfoTab";
import { SummaryTab } from "./components/SummaryTab/SummaryTab";

interface ISummaryBlockProps {
  data: IDocDetails;
}

const SummaryInfoBlock: React.FC<ISummaryBlockProps> = ({ data }) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("Information");
  const [editableData, setEditableData] = useState(cleanMarkdownData(data));
  const [editMode, setEditMode] = useState({
    title: false,
    dateOfPublication: false,
    class: false,
    decision: false,
    shortSummary: false,
    summary: false,
    provisions: false,
    participants: false,
    keywords: false,
  });
  const [parseChatAIData, setParseChatAIData] = useState("");
  const onDateChange = (date: Date) => {
    const updated = format(date, "yyyy-M-d");
    setEditableData({
      ...editableData,
      published: updated,
    });
  };

  const parseDataToChatAI = (data: string) => {
    setParseChatAIData(data);
    setActiveTab("ChatAI");
  };

  return (
    <div className="bg-base-color shadow-md rounded-lg flex flex-col h-full">
      <div className="flex justify-between border-b dark:border-gray-700">
        <button
          className={`w-1/2 text-center p-2 text-lg ${
            activeTab === "Information"
              ? "bg-base-color title-base-color border-b-2 dark:border-white"
              : "bg-gray-300 dark:bg-gray-700 text-base-color"
          }`}
          onClick={() => setActiveTab("Information")}
        >
          {t("docDetails.information")}
        </button>
        <button
          className={`w-1/2 text-center p-2 text-lg ${
            activeTab === "Summary"
              ? "bg-base-color title-base-color border-b-2 dark:border-white"
              : "bg-gray-300 dark:bg-gray-700 text-base-color border-x-2"
          }`}
          onClick={() => setActiveTab("Summary")}
        >
          {t("docDetails.summary")}
        </button>
        <button
          className={`w-1/2 text-center p-2 text-lg flex justify-center ${
            activeTab === "ChatAI"
              ? "bg-base-color title-base-color border-b-2 dark:border-white"
              : "bg-gray-300 dark:bg-gray-700 text-base-color"
          }`}
          onClick={() => setActiveTab("ChatAI")}
        >
          <span>
            {t("docDetails.chatAI")}
            <span className="text-del-green font-bold text-sm -top-3 relative">
              AI
            </span>
          </span>
        </button>
      </div>

      <div className="flex-grow">
        <div
          className={
            `${activeTab === "Information" ? "block" : "hidden"}` +
            " max-h-[calc(100vh-135px)] overflow-y-auto py-2 px-3"
          }
        >
          <InfoTab
            editableData={editableData}
            editMode={editMode}
            setEditableData={setEditableData}
            setEditMode={setEditMode}
            onDateChange={onDateChange}
          />
        </div>
        <div
          className={
            `${activeTab === "Summary" ? "block" : "hidden"}` +
            " max-h-[calc(100vh-135px)] overflow-y-auto py-2 px-3"
          }
        >
          <SummaryTab
            editableData={editableData}
            editMode={editMode}
            setEditableData={setEditableData}
            setEditMode={setEditMode}
            onParseData={parseDataToChatAI}
          />
        </div>
        <div className={`${activeTab === "ChatAI" ? "block p-1" : "hidden"}`}>
          <ChatAI
            summary={parseChatAIData}
            onRemoveSummaryBinding={setParseChatAIData}
          />
        </div>
      </div>
    </div>
  );
};

export { SummaryInfoBlock };
