import React, { useEffect, useRef, useState } from "react";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import { useTranslation } from "react-i18next";

interface IDocumentViewProps {
  fileUrl: string;
}

const DocumentView: React.FC<IDocumentViewProps> = ({ fileUrl }) => {
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const viewerRef = useRef<HTMLDivElement | null>(null);
  const webViewerInstanceRef = useRef<any>(null);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (viewerRef.current && !isInitialized.current) {
      WebViewer(
        {
          path: "/pdfjsExpress",
          licenseKey: "R1vwz6OyvsqjHwDE8PpI",
        },
        viewerRef.current,
      )
        .then((instance: any) => {
          webViewerInstanceRef.current = instance;

          const isDarkMode =
            document.documentElement.classList.contains("dark");

          if (isDarkMode) {
            instance.UI.setTheme("dark");
          } else {
            instance.UI.setTheme("light");
          }

          if (fileUrl) {
            try {
              instance.UI.loadDocument(fileUrl);
            } catch (error) {
              setError(t("pdfViewer.failedToLoad"));
            }
          }
        })
        .catch(() => {
          setError(t("pdfViewer.failedToInitialize"));
        });

      isInitialized.current = true;
    } else if (webViewerInstanceRef.current) {
      try {
        webViewerInstanceRef.current.UI.loadDocument(fileUrl);
      } catch (error) {
        setError(t("pdfViewer.failedToLoad"));
      }
    }
  }, [fileUrl]);

  useEffect(() => {
    const updateTheme = () => {
      const viewerInstance = webViewerInstanceRef.current;
      if (viewerInstance) {
        const isDarkMode = document.documentElement.classList.contains("dark");
        viewerInstance.UI.setTheme(isDarkMode ? "dark" : "light");
      }
    };

    const observer = new MutationObserver(updateTheme);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["class"],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  if (error) {
    return (
      <div className="error-message dark:text-white">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="document-viewer-container">
      <div ref={viewerRef} className="h-[calc(100vh-90px)]"></div>
    </div>
  );
};

export { DocumentView };
