import React, { useState } from "react";
import { SummaryInfoBlock } from "./components/SummaryInfoBlock/SummaryInfoBlock";
import { useParams } from "react-router-dom";
import { DocumentView } from "./components/DocumentView/DocumentView";
import { useQuery } from "@tanstack/react-query";
import { fetchItemDetails } from "./services/itemDetails.service";
import ReactSplit from "@devbookhq/splitter";

const DetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { regulator } = useParams<{ regulator: string }>();
  const [isResizing, setIsResizing] = useState(false);
  const [panelSizes, setPanelSizes] = useState([40, 60]);

  const { data: document, isLoading } = useQuery({
    queryKey: ["document", id],
    queryFn: () => fetchItemDetails(regulator, id),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  if (isLoading) {
    return <div className="line-loader"></div>;
  }

  const handleResizeFinished = (sizes: number[]) => {
    setIsResizing(false);
    setPanelSizes(sizes);
  };

  return (
    <div className="mt-2" style={{ height: "calc(100vh - 90px)" }}>
      <ReactSplit
        initialSizes={panelSizes}
        onResizeStarted={() => setIsResizing(true)}
        onResizeFinished={(_, newSizes: number[]) =>
          handleResizeFinished(newSizes)
        }
        gutterClassName="w-2 bg-gray-400 dark:bg-black"
        draggerClassName="bg-gray-600 dark:bg-gray-300"
      >
        <SummaryInfoBlock data={document} />
        <div className={isResizing ? "pointer-events-none" : ""}>
          <DocumentView fileUrl={document.fileUrl} />
        </div>
      </ReactSplit>
    </div>
  );
};

export { DetailsPage };
