import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FilterState } from "../../interfaces/FilterState";
import { Filters } from "../Filters/Filters";
import { IListItem } from "../../interfaces/IDocDetails";
import { ListItem } from "../ListItem/ListItem";

interface IListBlockProps {
  offsetCount: number;
  isLoading: boolean;
  data: {
    items: IListItem;
    filters: FilterState;
    total: number;
    exclude_ids: string[];
  };
  filtersSelected: FilterState;
  listData: { items: IListItem[]; filters: FilterState };
  onFilterChange: (updatedFilters: FilterState) => void;
  onOffsetChange: (offsetCount: number) => void;
  onTagRemove: (tag: string) => void;
}

const ListBlock: React.FC<IListBlockProps> = ({
  data,
  filtersSelected,
  listData,
  isLoading,
  offsetCount,
  onFilterChange,
  onOffsetChange,
  onTagRemove,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<string[]>([]);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const handleFiltersChange = (updatedFilters: FilterState) => {
    onFilterChange(updatedFilters);
    onOffsetChange(0);
  };

  const handleLoadMore = async () => {
    setIsFetchingMore(true);
    await onOffsetChange(offsetCount + 1);
    setIsFetchingMore(false);
  };

  const handleTagRemoveFromFilterState = (tag: string) => {
    onTagRemove(tag);
  };

  return (
    <>
      <div className="w-1/4 flex flex-col overflow-y-auto">
        <Filters
          filtersData={data?.filters || listData?.filters}
          filters={filtersSelected}
          tags={tags}
          onFiltersChange={handleFiltersChange}
        />
      </div>
      <div className="w-3/4 flex flex-col overflow-x-hidden overflow-y-auto">
        {listData?.items.length > 0 ? (
          <>
            {listData?.items.map((item: IListItem) => (
              <ListItem
                key={item.id}
                data={item}
                onTagsUpdate={(data: string[]) => setTags(data)}
                onTagRemoveFromFilterState={handleTagRemoveFromFilterState}
              />
            ))}
            <button
              className="mt-4 self-center px-4 py-2 bg-del-light-theme-green hover:bg-del-green text-white rounded-md dark:bg-del-dark-theme-dark-green dark:hover:bg-del-green"
              onClick={handleLoadMore}
            >
              {t("docDetails.loadMore")}
            </button>
          </>
        ) : (
          !isLoading && (
            <div className="flex flex-col items-center gap-6 h-full justify-center">
              <FaSearch size={90} color="grey" />
              <div className="text-2xl dark:text-white">
                {t("docDetails.noResultsFound")}
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export { ListBlock };
