import React, { useState, useEffect, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { formatDate } from "../ListItem/helpers/formatDate";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { IListItem } from "../../interfaces/IDocDetails";
import Markdown from "react-markdown";
import styles from "../../styles/Markdown.module.css";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { useTranslation } from "react-i18next";

interface ListItemProps {
  data: IListItem;
  isSelected: boolean;
  onSelect: (id: string, code: string) => void;
}

const FilteredItems: React.FC<ListItemProps> = ({
  data,
  onSelect,
  isSelected,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const titleRef = useRef<HTMLSpanElement>(null);

  const cleanSummary = (data: string): string => {
    return data?.replace(/```markdown\s*|```\s*/g, "");
  };

  useEffect(() => {
    if (titleRef.current) {
      setIsTruncated(
        titleRef.current.scrollWidth > titleRef.current.clientWidth,
      );
    }
  }, [data.title]);

  const handleSelectDocuments = (case_no: string, code: string) => {
    onSelect(code, case_no);
  };

  const toggleExpandSummary = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="px-3 py-2 bg-base-color rounded-lg shadow-md hover:shadow-2xl">
      <div className="text-lg text-del-light-theme-green dark:text-gray-300 font-bold flex justify-between items-center">
        <span className="flex items-center space-x-2 hover:underline dark:text-del-light-theme-green w-[93%]">
          <input
            type="checkbox"
            checked={isSelected}
            className="-ml-1 accent-del-light-theme-green dark:accent-del-green"
            onChange={() => handleSelectDocuments(data.case_no, data.code)}
          />
          <span
            ref={titleRef}
            onClick={() => handleSelectDocuments(data.case_no, data.code)}
            className={`${isTruncated ? "" : `tooltip-${data.id}`} truncate overflow-hidden text-ellipsis whitespace-nowrap tooltip-target`}
          >
            {data.title}
          </span>
          {isTruncated && (
            <Tooltip
              anchorSelect={`.tooltip-${data.id}`}
              place="top"
              positionStrategy="fixed"
              className="text-xs font-light z-50"
              content={data.title}
              style={{ maxWidth: "300px", whiteSpace: "normal" }}
            />
          )}
        </span>
        <a
          href={`/details/${data.regulatorCode}/${data.case_no}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ml-2"
        >
          <FaArrowRight
            className={`text-xl icon-del-green focus:outline-none tooltip-anchor-${data.id}`}
          />
        </a>
        <Tooltip
          anchorSelect={`.tooltip-anchor-${data.id}`}
          place="top"
          className="text-xs font-light z-50"
          content={t("tooltips.openOriginalDocument")}
        />
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.dateOfPublication")} </span>
        {formatDate(data.published)}
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.regulators")} </span>
        {!!data.regulatorName ? data.regulatorName : data.regulatorCode}
      </div>
      <div className="text-sm text-secondary-color">
        <span className="font-semibold">{t("docInfo.class")} </span>
        {data.class_name}
      </div>
      <div className="flex items-start">
        <div
          className={`text-sm text-secondary-color ${
            isExpanded ? "" : `${styles["markdown-truncate"]}`
          }`}
        >
          <Markdown
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
            className={`${styles.markdownBlock} text-secondary-color`}
          >
            {`**${t("docInfo.shortSummary")}** ${cleanSummary(data.shortSummary)}`}
          </Markdown>
        </div>
        <button
          onClick={toggleExpandSummary}
          className="-mr-2 text-black focus:outline-none dark:text-gray-400"
        >
          {isExpanded ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
        </button>
      </div>
      <div className="flex flex-wrap mt-1 gap-y-1">
        {data?.keywords?.map(
          (
            keyword:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined,
            index: React.Key | null | undefined,
          ) => (
            <span
              key={index}
              className="inline-flex items-center justify-center keyword-chip-color"
            >
              {keyword}
            </span>
          ),
        )}
      </div>
      <hr className="my-1 border-gray-300 dark:border-gray-600" />
      {data.highlightedText && (
        <div className="text-sm text-base-color">{data.highlightedText}</div>
      )}
    </div>
  );
};

export { FilteredItems };
